.leaflet-container {
  height: 100vh;
  width: 100%;
}
.app-map {
  height: 100vh;
}

.searchBar {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.searchIcon {
  position: absolute;
  left: 10px;
  color: #ced4da;
  font-size: 1.5rem;
  cursor: pointer;
}

.searchInput {
  width: 100%;
  padding: 6px 6px 6px 40px; /* Adjust padding to fit the icon */
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
  outline: none;
  box-sizing: border-box;
}

.searchInput::placeholder {
  color: #ced4da
}

.searchInput:focus {
  border: 1px solid #ced4da;
  outline: none;
  box-shadow: none;
}
