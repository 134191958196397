.reportWrapper {
  margin-top: 20px;
  justify-items: center;

  .reportItemContainer {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

    .reportItem {
      height: 295px;
      background: #fff;
      border-radius: 5px;
      box-shadow: #e5e5e5 0 0 5px 1px;
      display: flex;
      flex-direction: column;

      .reportTitle {
        height: 20%;
        background-color: #47a3c7;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        color: #fff;
        font-weight: 500;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        padding: 10px;
      }

      .reportDescription {
        padding: 10px;
      }

      .ccsReportTitle {
        color: rgb(0, 171, 85);
        background-color: rgba(rgb(0, 171, 85), 0.08);
      }
    }
  }
}

.report-background {
  background-color: #f4f6f8;
}

.rs-overflow {
  height: 100vh;
  overflow: auto;
}
.ccsReportTitle {
  color: rgb(0, 171, 85);
  background-color: rgba(rgb(0, 171, 85), 0.08);
}
.p-20 {
  padding: 20px;
}