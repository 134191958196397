.patient-demographics {
  padding: 10px 0px;
  width: 100%;
  gap: 0.6rem;
  display: flex;
  flex-direction: column;

  .patient-demographics-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .patient-details-wrapper,
    .patient-attributes-wrapper {
      width: 48%;
      display: flex;
      flex-direction: column;
      gap: 5%;

      .p-table {
        width: 100%;

        .p-td {
          padding: 0.5rem;
          text-transform: capitalize;
          height: 35px;

          &:first-child {
            width: 65%;
          }

          +.p-td {
            width: 30%;

          }

        }
      }

    }

    .flex-divider {
      border-left: 2px solid rgb(210, 208, 208);
      height: 100%;
      /* Adjust height as needed */
      margin: 0 10px;
    }

  }

  .note {
    width: 100%;
  }
}