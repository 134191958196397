.editPage {
  .patient-set-outer {
    width: 100%;
    padding: 30px 50px 30px 50px;
  }

  .patient-set-inner {
    padding: 20px 20px 35px 20px;
  }

  .MuiTypography-body1 {
    font-size: 0.9rem !important;
    font-weight: 600 !important;
    color: #808182;
  }

  .modal-input {
    height: 37px;
    letter-spacing: 0.00938em;
    font-size: 15px;
    color: #a5a4ba;
    line-height: 1.1876em;
    width: 100%;
    margin-top: 5px;
  }

  .edit-table {
    border: unset;

    th {
      height: 30px;
      border: unset;
      font-size: 13px;
      background-color: #ececec;
    }

    td {
      border: unset;
      margin-left: auto;
      margin-right: auto;
      padding: 10px;
    }
  }

  .ps-table-refresh {
    margin-left: 0px;
    margin-right: 3px;
  }

  .clear-icon {
    cursor: pointer;
  }

  .edit-table-user {
    display: block;
    font-size: 15px;
    color: #666;
  }

  .mui-textfield-form .MuiFormControl-root {
    width: 100%;
    margin-top: 5px;
  }
}

.confirm-edit-button {
  color: #0080b2 !important;
}

.file-upload-widget {
  max-height: 520px !important;
  margin: 15px;
}

.upload-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  margin-top: 5px;
}


.upload-subtitle {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #666;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 20px;
}

.upload-submit {
  display: flex;
  justify-content: center;
  margin-top: 28px;

  .MuiButton-containedPrimary {
    background-color: #0082b2 !important;
    width: 100% !important;
  }
}

.disparity-wrapper {
  display: flex;
  flex-wrap: wrap;

  .item {
    flex-basis: calc(50% - 10px);
    /* Set the width to 50% minus some margin */
    margin: 5px;
    /* Add some margin for spacing */
  }
}