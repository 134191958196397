.fhs-themed-button {
    color: #0082b2;
    border: #0082b2 solid 1px;
    font-size: 14px;
    line-height: 30px;
    margin: 0 10px;
    background: #fff;
    border-radius: 15px;
    cursor: pointer;
    outline: none !important;
    text-transform: capitalize;
}

.fhs-themed-button:hover {
    outline: none !important;
    color: #fff;
    background-color: #0082b2;
}

.fhs-themed-button:disabled {
    color: #fff;
    background-color: #0082b2;
    opacity: 0.5;
    cursor: not-allowed;
}