$primary-color: #0080b2;

// css for patientlist file
.patient-list-main-container {
    .patient-list-inner-container {
        padding: 0 !important;
        margin: 0 !important;

        .patient-list-paper-container {
            padding: 16px 0px !important;

            .main-grid-container {
                .sub-grid-container {
                    padding: 0px !important;
                    margin: 0 !important;
                    padding: 0px 8px !important;
                }
            }

            .MuiAvatar-root {
                border-radius: 0;

                img {
                    right: 0;
                }
            }
        }
    }

    .save-filter-button {
        color: #007BFF !important;
    }

    .MuiButton-root {
        text-transform: none;
    }

    .MuiTab-textColorPrimary {
        font-size: medium;
        font-weight: 700;
        text-transform: capitalize;
    }

    .MuiTab-textColorPrimary.Mui-selected {
        color: #47a3c7 !important;
    }
}

// css for advance filter
.advance-filter-outer-container {
    z-index: 1202 !important;

    .inner-container {
        width: 530px !important;

        .filter-list {
            .list-header {
                font-weight: 600;
                color: $primary-color;
                font-size: 16px !important;
            }

            .filter-accordion-container {
                position: relative;

                .filter-accordion {
                    box-shadow: none !important;
                    border-bottom: 1px solid #d9d9d9;
                    border-radius: 0 !important;

                    .expand-icon {
                        fill: $primary-color !important;
                    }

                    .title {
                        color: $primary-color !important;
                        font-size: 14px !important;
                        font-weight: 600 !important;
                    }
                }

                .outlier-accordion {
                    box-shadow: none !important;
                    border-radius: 0 !important;

                    .expand-icon {
                        fill: $primary-color !important;
                    }

                    .title {
                        color: $primary-color !important;
                        font-size: 14px !important;
                        font-weight: 600 !important;
                    }
                }

                .accordion-details-container {
                    padding: 5px 20px 20px 20px !important;
                    max-height: 400px !important;
                    overflow-y: scroll !important;

                    .filter-checkbox {
                        width: 14px !important;
                        height: 14px !important;
                        border: 1px solid red !important;
                    }

                    .filter-checkbox:checked {
                        border-color: #ff0000; // Change the color when checked
                    }
                }
            }

            .list-item-container {
                .others-filters-button div span {
                    font-size: 14px !important;
                    font-weight: 600 !important;
                }
            }
        }

        .filter-loader-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}


// css for applied filter list
.patient-list-applied-filter-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    .list-container {
        display: flex;
        border-radius: 40px;
        align-items: center;
        gap: 10px;
        background-color: #E7E7E7 !important;
        padding: 7px 10px;

        .list-main-heading {
            font-size: 14px;
            font-weight: 500;
        }

        .inner-list {
            display: flex;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;

            .inner-list-item {
                display: flex;
                align-items: center;
                gap: 5px;
                border-radius: 10px;
                padding: 2px 5px;
                background-color: #fff;
                cursor: pointer;

                .deselected {
                    color: #D83B01 !important;
                }
            }
        }
    }
    .applied-filter-list-info-icon{
        fill: "#9D9EA2 !important";        
    }
    .applied-filter-list-info-icon-blinking {
        animation-name: notification-icon-animation;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out;
    }
    @keyframes notification-icon-animation {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0.3;
        }
      }
}

.filter-modal {
    .gender-radio-group {
        .MuiRadio-root {
            padding: 10px 10px 10px 0px;
        }
    }
    .MuiButton-root {
        text-transform: none;
    }
}

.expand-icon-label{
    display: flex;
    align-items: center; /* Align items vertically */
}

.expand-icon-label svg {
    margin-right: 8px; /* Adjust the spacing between the checkbox and label */
  }
  
.panel-pos-unset {
    position: unset;
}