.triangle_ov {
  position: relative;
  height: auto;
  clip-path: polygon(50% 0, 0% 100%, 100% 100%);
  width: 290px;
  float: right;
}
.triangle_detail {
  position: relative;
  height: auto;
  clip-path: polygon(50% 0, 0% 100%, 100% 100%);
}
.triangle_ov div {
  height: 70px;
  margin: 0 auto 10px;
  color: #383838;
  line-height: 110px;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
  font-weight: 400;
}
.triangle_detail div {
  height: 85px;
  margin: 0 auto 10px;
  color: #383838;
  line-height: 90px;
  text-align: center;
  font-size: 17px;
  cursor: pointer;
}
.top {
  background: #f4755f;
}
.top:hover {
  background: #fa9280;
}
.middle {
  background: #fcad60;
}
.middle:hover {
  background: #fcba7b;
}
.bottom {
  background: #a6e7db;
}
.bottom:hover {
  background: #c0eae2;
}
.last {
  background: #88d479;
}
.last:hover {
  background: #9cd490;
}
.pyramidLeft {
  float: left;
}


text.apexcharts-text.apexcharts-datalabel-value {
  font-size: 18px;
}

.z-index-outer {
 z-index: 5;
}

#pie_chart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 50px;
}