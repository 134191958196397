@import "../../mixin.scss";

.comparisonPageOuter {
  padding: 45px 58px 60px 58px;

  .emulatHd {
    h2 {
      font-size: 24px;
      line-height: 40px;
      font-weight: 500;
      padding-top: 10px;
      text-transform: capitalize;

      @include respond-to(media-sm) {
        font-size: 18px;
      }

      img {
        display: inline-block;
        padding-right: 10px;
      }
    }
  }


  .comparisonOuter {
    background-color: #ffffff;
    box-shadow: 0 6px 16px 2px rgba(90, 99, 112, 0.2);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    .header__container {
      display: flex;
      justify-content: space-between;
      background-color: #fcfcfd;
      box-sizing: border-box;
      margin: 0 auto;
      padding: 30px 25px 35px;
      border-radius: 16px 16px 0 0;
      box-shadow: inset 0 -2px 0 0 rgba(225, 228, 232, 0.55);

      .header-title {
        font-size: 18px;
        font-weight: 600;
      }
    }

    .silulateTitlHd {
      font-size: 18px;
      font-weight: 400;
      color: #282828;
      padding-bottom: 10px;

      @include respond-to(media-sm) {
        font-size: 16px;
      }
    }

    .comparisonTableOuter {
      @include respond-to(media-sm) {
        overflow: auto;
      }

      .com_table_container {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
        
        .outer__table {
          table-layout: auto;
          border-collapse: separate;
          border-spacing: 0;
          width: 100%;
          max-width: 100%;
          white-space: break-spaces;



          .tRow td:first-child,
          .tRow th:first-child {
            padding-left: 20px;
          }

          thead th {
            color: #8c94a1;
            font-weight: 500;
            letter-spacing: -0.14px;
            font-size: 14px;
            line-height: 15px;
            padding: 18px 0px 16px 30px;
            vertical-align: top;
            border-bottom: 1px solid #edeef2;
            text-align: right;
            white-space: normal;
          }

          .table-head {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
          }

          .statsIcon .MuiSvgIcon-root {
            width: 18px !important;
            margin-left: 3px;
            margin-top: -5px;
            cursor: pointer;
          }

          .statsIcon .MuiAvatar-root {
            width: 16px;
            height: 16px;
            font-size: 0.6rem;
            margin-left: 3px;
            margin-top: -3px;
            cursor: pointer;
          }

          thead th:last-child {
            padding-right: 20px;
          }

          tr td:last-child {
            padding-right: 20px;
          }

          tr>th,
          tr>td {
            min-width: 100px;
          }

          tr {

            td:first-child,
            th:first-child {
              text-align: left;
              font-weight: normal;
              position: sticky;
              left: 0px;
              z-index: 40;
              background-color: inherit;
              min-width: 230px;
              // border-right: 1px solid #edeef2;

            }

            &:nth-child(even) {
              background-color: #f2f2f2;
            }

            &:nth-child(odd) {
              background-color: white;
            }
          }

          .entryHasNotes td span.cohort-link {
            cursor: pointer;
            color: #6baaed;
          }

          .entryHasNotes td {
            color: #333333;
            font-size: 14px;
            letter-spacing: -0.24px;
            line-height: 22px;
            padding: 12px 4px 4px;
            vertical-align: top;
            border-top: 1px solid #edeef2 !important;
            height: 55px;

            &.medium {
              color: #a6e7db;
              text-transform: capitalize;
            }

            &.high {
              color: #fcad60;
              text-transform: capitalize;
            }

            &.low {
              color: #88d479;
              text-transform: capitalize;
            }

            &.critical {
              color: #f4755f;
              text-transform: capitalize;
            }
          }
        }

      }

    }
  }

}