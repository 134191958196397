.patient-recommendations {
  padding: 0px 10px 10px 10px;
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 250px);
  background-color: white;
  margin-top: 2px;

}

.recommendation-table {
  word-break: normal;
  .MuiTableHead-root {
    & .MuiTableRow-root {
      & .MuiTableCell-root {
        color: #0082b2;
        line-height: 1.2rem;
      }
    }
  }
}

.not-available {
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 100%;
  height: 100%;
}