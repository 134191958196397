
.speedometer-new-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    .speedo-meter-heading{
      font-size: 14px;
      margin-top: 13px !important;
      font-weight: 500;
    }

    .speedometer-new {
      width: 250px;
      height: 250px;
      padding: 50px;
      border-radius: 50% 50%;
      background-color: #fff !important;
      margin: 60px 0px 0px 0px;
      position: relative;

      .needle {
        width: 7px;
        height: 50%;
        background: #000;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-left-radius: 100%;
        border-top-right-radius: 100%;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 50%;
        animation:move 5s 1;
        transform:rotate(310deg);
        transform-origin:bottom;
    }
    
    .labels {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // display: flex;
        // justify-content: space-between;
        // align-items: flex-end;
        // transform: translateY(-50%);

        .item {
            position: absolute;
            border-radius: 50%; /* Make items circular */
            display: flex;
            align-items: center;
            justify-content: center;
            color: #333; /* Text color */
          }
      }
    }

    .speedometer-footer{
      margin-top: -90px;
      z-index: 100;
      position: relative;
      display: flex;
      align-items: center;
      .text{
        margin: 0 !important;
        padding: 0 !important;
        font-size: 14px !important;
        font-weight: 400;
      }
      .warning-yellow-icon{
        width: 20px;
        height: 20px;
      }
    }

}

@keyframes move {
  0% {
      transform:rotate(-90deg);
  }
  50% {
      transform:rotate(90deg);
  }
  100% {
      transform:rotate(-90deg);
  }
}
  

  







