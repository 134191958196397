.report-title {
    font-size: 18px;
    display: flex;
    justify-content: center;
    margin: 20px 0px 20px 0px;
    font-weight: 600;
}

.select-box {
    width: 100%;
}

.query-label {
    color: #666;
    margin-top: 20px;
}

.datepicker {
    width: 100%;
    .MuiInputBase-formControl {
        width: 100%;
    }
   
}

.MuiDayPicker-weekContainer{
    .MuiButtonBase-root {
        width: 36px !important;
    }
}

.error{
    color: red;
}

.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
    margin: unset !important;
    width: 100% !important;
    max-width: unset !important;
}

.btn-back{
    position: absolute;
    right: 30px;
}

.rp-overflow{
    padding: 30px 10px;
    overflow: auto;
    max-height: 100vh;
}

.ccs__iframe__active {
    .uploadpage .upload-inner .upload-submit .MuiButton-containedPrimary {
        background-color: rgb(0, 171, 85) !important;
        font-family: "Public Sans", sans-serif !important;
    }
}