.dashboard-header {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    background-color: #fff !important;

    .dashHdr_left {
      padding: 10px 0;
      float: left;
      width: 100%;

      .patient-set-name {
        color: #4d4b5f;
        font-size: 1rem;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        line-height: 1.2;
        margin-top: 2px;
      }

      .patient-sets-buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        margin-top: 9px;
      }

      .patient-set-id {
        color: #757575;
        font-size: 0.80rem;
        font-family: 'Ubuntu', sans-serif;
        line-height: 1.4;
        letter-spacing: 0;
        margin-top: 4px;
      }
    }

    .dashHdr_center {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;

      .MuiButton-root {
        width: 100% !important;
      }

      .page_title {
        text-transform: capitalize;
        padding-top: 5px;
        font-size: 16px;
        line-height: 1.6rem;
        font-weight: 600;
        margin-bottom: 10px;
        color: #4d4b5f;   
        text-align: center;
      }
    }
    .dashHdr_right {
      float: right;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .hdrRight_gridBx {
        display: flex;
        justify-content: flex-end;
        padding: 12px 5px 13px 0;
        a {
          //display: inline-block;
          margin-left: 20px;
          //height: 25px;
          position: relative;
          width: 25px;
          vertical-align: top;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: auto;
            max-width: unset;
            max-width: unset;
          }
          .help-icon {
            width: 25px;

          }
          .grid-icon {
            width: 20px;
          }
        }
        a:last-child {
          margin-right: 7px;
        }
        a:last-child {
          margin-right: 7px;
        }
        .profileName {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #0080b2;
          border-radius: 50%;
          text-align: center;
          font-size: 15px;
          padding: 4px;
          text-decoration: none;
          color: #fff;
          font-weight: 600;
          width: 33px;
          height: 33px;
        }
        .exit-aka {
          margin-left: 10px;
          width: 53%;
        }
      }

      .analysis-type-dropdown{
        padding-right: 20px;
        .MuiSelect-select {
          padding: 5px 40px 5px 10px;
          font-size: 15px;
        }
      }
    }
  }

.MuiListItemText-root span {
  font-size: 16px !important;
}

.MuiAppBar-root {
  background-color: #fff !important;
}

.MuiToolbar-root {
  padding-left: 18px !important;
  padding-right: 8px !important;
}

.patient-sets-dropdown {
  position: relative;
  ul {
    min-width: 200px;
    padding: 8px;
    background-color: #fff;
    border-radius: 8px;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: #919eab33 0px 0px 2px 0px, #919eab33 0px 12px 24px -4px;
    max-height: 320px;
    overflow-y: auto;
    li {
      list-style: none;
      padding: 6px 12px 11px 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      border-bottom: 1px solid #ccc;
      &:last-child {
        border-bottom: 0px;
      }
      label {
        color: #000;
        font-size: 0.80rem;
        margin-bottom: 0 !important;
        line-height: 14px;
        font-weight: 700;
      }
      p {
        line-height: 14px;
        margin-bottom: 0px;
        font-size: 14px;
      }
    }
  }
}


.patient-set-option {
  cursor: pointer;
}

.patient-set-option:hover {
  background-color: rgb(234, 249, 255);
}