.heroHeader {
  background-color: #47a3c7;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .section__container {
    padding: 0 30px;
  }

  .navBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px 30px 0;

    .logoContainer {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .logoImg {
      width: 38px;
      height: auto;
    }

    .logo {
      color: #fff;
      font-size: 1.7rem;
      font-weight: 500;
    }

    .btnGroup {
      display: flex;
      align-items: center;

      .btn {
        cursor: pointer;
        background-color: transparent;
        border: none;
        border-radius: 16px;
        display: flex;
        align-items: center;
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -0.24px;
        line-height: 17px;
        padding: 0;
        width: auto;

        .btnLabel {
          position: relative;
          margin-left: 5px;
        }

        .btnLabel::after {
          content: "";
          position: absolute;
          left: 1px;
          bottom: -2px;
          border: none;
          border-top: 2px dotted #ffffff;
          transition: border-top 200ms ease-in 0s;
          opacity: 0.7;
          height: 1px;
          width: 98%;
        }
      }
    }
  }

  .subHeading {
    margin-top: 26px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 45px;

    .reportTitle {
      margin: 0;
      font-size: 34px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 41px;
      margin-bottom: 12px;
    }

    .dateRange {
      display: inline-flex;
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      letter-spacing: -0.27px;
    }
  }

  .detailedHeader {
    display: flex;
    justify-content: space-between;
    background-color: #fcfcfd;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 30px 40px 35px;
    border-radius: 16px 16px 0 0;
    max-width: 75%;
    box-shadow: inset 0 -2px 0 0 rgba(225, 228, 232, 0.55);

    .colLeft {
      display: flex;
      flex-direction: column;
      align-self: flex-end;

      .infoLeft {
        font-size: 18px;
        color: #333;
        font-weight: 500;
        min-width: 20px;
        margin-right: 5px;
      }

      .infoRight {
        color: #8c94a1;
        font-size: 18px;
        letter-spacing: -0.28px;
        line-height: 19px;
        text-transform: capitalize;
      }
    }

    .badgeWithoutClient {
      margin-left: 0;
    }

    .timeInfo {
      .label {
        color: #8c94a1;
        font-size: 16px;
        letter-spacing: -0.25px;
        line-height: 19px;
      }

      .value {
        color: #333333;
        font-size: 40px;
        font-family: "Helvetica Now Display";
        font-weight: 500;
        letter-spacing: 0;
        line-height: 48px;
      }
    }
  }
}


.ccs__iframe__active.heroHeader{
  background-color: rgb(0, 171, 85);
}