.main-iframe-container {
  margin: 30px !important;
}

.loader-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader1 {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
  }

  .loader-text {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .time-count-1 {
      margin: 0;
      padding: 0;
    }

    .time-count-2 {
      margin: 0;
      padding: 0;
      text-align: center;
      line-height: 1.5;
    }

    .time-text {
      margin: 0;
      padding: 0;
      text-align: center;
      line-height: 1.3rem;
    }
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.error-container {
  width: 100%;
  height: 100vh;
  background-color: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0px;
  }

  p {
    color: #cc3b3b;
    font-size: 14px;
    margin-top: 20px;
  }
}

.error-container-2 {
  width: 100%;
  padding: 50px 0px;
  background-color: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0px;
  }

  p {
    color: #cc3b3b;
    font-size: 14px;
    margin-top: 20px;
  }
}

.main-container {
  padding: 0px 13px 25px 13px;
}

.chart-container {
  margin-top: 25px;
  position: relative;

  .chart {
    border: 1px solid #dedede;
  }
}

// For Tooltip
.toolTipHeader {
  background-color: #b3b3b1;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.tooltipInnerContainer {
  // background-color: #000;
  padding: 10px;
}

.itemRowInToolTip {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  font-size: 12px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(167, 165, 165);
}

.toolTipHeader2 {
  background-color: #008FFB;
  color: #fff;
  padding: 5px 10px;
}

.blue-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #008FFB;
}

.cohort-analysis-table {
  width: 95%;
  margin-left: 5%;

  // border: 1px solid #acaaa0;
  thead {
    background-color: #d1d0ce;

    tr {
      .first-cell {
        // display: none;
        border: none;
        background-color: #fff;
      }
    }
  }

  thead>tr>:nth-child(1) {
    background-color: #d1d0ce;
    text-align: left;
  }

  tbody>tr>:nth-child(1) {
    background-color: #d1d0ce;
    text-align: left;
  }

  td,
  th {
    border: 1px solid #acaaa0;
    text-align: center;
    padding: 5px 5px;
    font-size: 12px;
  }

  td {
    cursor: pointer;
  }

  .empty-data-cell {
    border: 1px solid #fff;
  }

  .orange-bg {
    background-color: #fa8616c2;
  }

  .yellow-bg {
    background-color: #f7d8588f;
  }

  .green-bg {
    background-color: #0b9543b0;
  }
}

.custom-marker-series1 {
  width: 100px;
  height: 100px;
  background-color: red;
}

.empty-container{
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #e6feff;
  padding: 50px 100px;
  margin-top: 20px;
}

.apexcharts-menu-icon{
  display: block;
}
.apexcharts-menu .apexcharts-menu-item.exportSVG{
  display: none;
}
