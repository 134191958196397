@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

// constants
$primary-color: #0080b2;

// mixin
@mixin common-background-border {
  background-color: #fff;
  border-radius: 12px;
}
@mixin font-size($size, $weight, $height, $color) {
  font-family: Lato;
  font-size: $size !important;
  font-weight: $weight;
  line-height: $height;
  letter-spacing: -0.03em;
  color: $color !important;
}

// css
.patient-summary-container {
  background-color: #f5f5f5;
  padding-bottom: 100px;
  .header-container {
    background-color: #ffffff;
    height: 70px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    .header-right {
      display: flex;
      max-width: 490px;
      align-items: center;

      .last-update-box {
        display: flex;
        flex-direction: row;
        // width: 170px;
        // height: 21px;
        justify-content: center;
        // flex-wrap: wrap;
        align-items: center;
        border-radius: 10px;

        .detail-text {
          font-family: Lato;
          font-size: 12px !important;
          font-weight: 600;
          color: #0080b2;
          width: 100px;
        }
      }

      .summary-logo {
        width: 71px;
        height: 71px;
      }
      .summary-logo-text {
        height: 71px;
      }

      .notification-container{
        padding: 20px !important;

        .MuiBadge-badge {
          color: #fff;
          background-color: #FD0808 !important;
          font-family: Lato;
        }
      }
    }
  }

  .main-container {
    max-width: 1440px;
    margin: 0 auto;
    .patient-summary-details-container {
      margin: 0 28px;
      padding: 20px !important;

      .share-icon {
        width: 14px !important;
        height: 16px !important;
        cursor: pointer;
      }

      .patient-details-container {
        height: 71px;
        margin-bottom: 20px;
        display: grid;
        border-radius: 12px;

        .patient-details-card-header {
          padding: 5px 28px 0px 20px !important;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .header-text {
            @include font-size(19px, 600, 29px, $primary-color);
          }

          .right-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;

            .last-update-box {
              display: flex;
              flex-direction: row;
              background-color: #0080b2;
              width: 170px;
              height: 21px;
              justify-content: center;
              flex-wrap: wrap;
              align-items: center;
              border-radius: 10px;

              .detail-text {
                font-family: Lato;
                font-size: 12px !important;
                font-weight: 600;
                color: #fff;
              }
            }
          }
        }

        .patient-details-card-body {
          padding: 3px 60px 3px 14px !important;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;

          .patient-details-cell {
            display: flex;
            flex-direction: row;

            .patient-details-info-cell {
              margin-left: 6px;
              font-weight: 600;
              font-family: Lato;
            }

            .patient-details-skeleton { 
              margin-left: 6px;
              margin-top: -6px;
            }

          }
        }

      }

      .risk-section-main-container {
        column-gap: 20px;
        display: grid;
        grid-template-columns: 1fr;
        margin-bottom: 20px;;

        .left-details{
          min-height: 60px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .risk-score-section {
            background-color: #ffffff;
            padding: 13px 28px 13px 20px !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 12px;
          
            .risk-score-text {
              @include font-size(19px, 600, 29px, $primary-color);
            }
            
            .left-container {
              display: flex;
              align-items: center;
              gap: 10px;
            }
            
            .right-container {
              display: flex;
              align-items: center;
              gap: 33px;
              .risk-icon-container {
                // background-color: #ff2727;
                display: flex;
                align-items: center;
                border-radius: 25px;
                padding: 6.5px 28px !important;

                .high-risk-text {
                  font-family: Lato;
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 16px;
                  letter-spacing: -0.03em;
                }
              }
            }
          }
        }
        .azara-section {
          background-color: #fff;          
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          border-radius: 12px;

          .link-text {
            font-family: Lato;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: -0.03em;
            color: #0080b2;
            text-decoration: underline;
          }
        }
      }

      .grid-container {
        .grid-container-left {
          display: flex;
          flex-direction: column;
          gap: 20px;
          max-width: 678px;

          *::-webkit-scrollbar {
            width: 8px;
            height: 8px;
          }
        }

        .grid-container-right {
          display: flex;
          flex-direction: column;
          gap: 20px;
          max-width: 678px;

          *::-webkit-scrollbar {
            width: 8px;
            height: 8px;
          }
        }
      }
    }
    ::-webkit-scrollbar {
      width: 7px;
      border-radius: 100px;
    }
    ::-webkit-scrollbar-thumb {
      border: none;
      background-color: #7d7b7b;
    }
    ::-webkit-scrollbar-track {
      background-color: #d9d9d9;
    }
  }
  .apexcharts-xaxis-label {
    color: #1b2632;
    font-family: Lato !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 18px */
    letter-spacing: -0.36px;
  }
  .apexcharts-yaxis-label {
    color: #1b2632;
    font-family: Lato !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 18px */
    letter-spacing: -0.36px;
  }
  .selected-border{
    border: 1.5px solid #FD0808 !important;
  }
}


.arrow_box {
  width: 150px;
  background-color: #0080b2;
  color: #fff;

  .date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "Lato";

    .main {
      font-weight: 600;
      font-family: "Lato";
    }
  }
}

.summary-container {
  background-color: #E3F5FF;
    width: auto;
    margin-bottom: 20px;
    display: grid;
    border-radius: 12px;

    .caution-container{

      display: flex;
      justify-items: center;
      gap: 10px;
      margin-top: 10px;
      align-items: flex-end;
      justify-content: space-between;

      .disclaimer-text {
        font-family: Lato;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        margin-top: 5px;
        // margin-left: 28px;
        font-style: italic;
        letter-spacing: -3%;
        color: #141414;
      }  
    }

  .summary-card {
    display: grid;
    grid-template-columns: 0.5fr 8fr;
    column-gap: 0px;
    

    .summary-card-header {
      padding: 5px 20px 5px 28px !important;
      display: flex;
      justify-content: space-between;

      .header-text {
        @include font-size(19px, 600, 29px, $primary-color);
      }
    }

    .summary-card-body {
      padding: 10px 20px 10px 0px !important;
      
      .summary-text {
        font-family: Lato;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
      }

      .expandable-box {
        cursor: pointer;

        .expand-button {
          color: #0066CB;
          font-family: Lato;
          font-weight: 500;
          text-decoration: underline;
          padding: 0px;
          margin-left: 10px;
        }

        .expand-icon {
          width: auto;
          height: 20px;
          margin-left: 5px
        }
      }


    }
  }
}

.notification-box{
  background-color: #fff;
  min-width: 404px;
  min-height: 90px;
  max-height: 440px;

  .notification-body{
    padding: 0px 10px !important;
    
    .notification{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 10px!important;
      margin-top: 5px;
      cursor: pointer;

      .notification-content{
        // margin-left: 10px;

        .text {
          font-family: Lato;
        }

        .main {
          font-family: "Lato";
          font-size: 13px !important;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
        }

        .smart_alert {
          font-family: "Lato";
          font-size: 13px;
          font-style: italic;
          font-weight: 400;
          line-height: 20px;
          color: #0066CB;
          cursor: pointer;
          border-bottom: 1px solid #0066CB !important;
        }

        .date{ 
          color: #7D7B7B;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: "Inter";
          font-size: 10px !important;
          font-weight: 400;
          line-height: 12.1px;
          margin-top: 5px;
        }

        .date_time {
          display: flex;
          align-items: center;
        }

        .priority {
          padding: 5px 10px 5px 10px;
          gap: 5px;
          border-radius: 5px 0px 0px 0px;
          border: 0.5px 0px 0px 0px;
          background-color: #F6EFEF;
          border: 0.5px solid #F6EFEF;
          font-family: "Lato";
          font-size: 12px;
          font-weight: 400;
          line-height: 14.4px;
          text-align: center;
          color: #FA0607;
        }

      }

      .notification-icon{
        margin-top: -2px;
      }
    }

  }

  .clear-all-button {
    // padding: 5px 10px 5px 10px;
    gap: 5px;
    border-radius: 5px;
    border: 1px solid #007BFF;
    background: #FFFFFF;
    color: #007BFF;
    box-shadow: none;
    text-transform: none;
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;    
  }

  .view-all-button {
    gap: 5px;
    border-radius: 5px;
    border: 1px 0px 0px 0px;
    text-transform: none;
    background-color: #007BFF;
    border: 1px solid #007BFF;
    box-shadow: none;
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;    
  }

  .clear-all-button:hover {
    border: 1px solid #007BFF !important;
  }

  .view-all-button:hover {
    background-color: #007BFF !important;
  }
}

.highlighted {
  z-index: 5; /* Place the box above all other elements on the page */
  border: 1px solid red; /* You can customize the border color and width */
  transition: transform 0.5s ease, box-shadow 0.5s ease; /* Define the transition duration and easing */
}

/* Highlight effect */
.highlighted.active {
  transform: scale(1.1); /* Increase the size by 10% */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Add a shadow effect */
}

.notification-icon-animated {
  // fill: "#FD0808 !important";
  animation-name: notification-icon-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

@keyframes notification-icon-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

.summary-tabs {
  .MuiTab-textColorPrimary {
    font-family: Lato !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 19.2px !important;
    letter-spacing: -0.03em !important;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: #0080b2 !important;
  }
}

.smart-alert-card{
    background-color: #ffffff;
    padding: 13px 28px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;

    .smart-alert-text{
      font-family: Lato;
      font-size: 19px !important;
      font-weight: 600;
      line-height: 29px;
      letter-spacing: -0.03em;
      color: #0080b2 !important;
    }
}

.sdoh-analysis-card{
  background-color: #ffffff;
  padding: 15px 28px 15px 20px!important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  margin-top: 20px;

  .smart-alert-text{
    font-family: Lato;
    font-size: 19px !important;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: -0.03em;
    color: #0080b2 !important;
  }
}


.export-button {
  display: none;
}
