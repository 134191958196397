@import "./mixin.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-bg-color {
  background-color: rgb(244, 246, 248); 
}

.widget-bg-shadow {
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  border-radius: 14px !important;
}

.DashboardOuter {
  min-height: 100vh;
  
  .dashMidCont {
    padding-top: 1px;

    .container {
      max-width: 1200px;
      margin-top: 40px;
    }
    .dashHalf_Left {
      float: left;
      width: 48%;
      @include respond-to(media-lgs) {
        float: none;
        width: 100%;
      }
    }
    .dashHalf_Right {
      float: right;
      width: 48%;
      @include respond-to(media-lgs) {
        float: none;
        width: 100%;
      }
    }
    .GraphBg {
      background: #fff;
      padding: 20px;
      height: 100%;
      margin-bottom: 0px;
      position: relative;
      box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
      border-radius: 14px;

      .graphRefBtn {
        position: absolute;
        top: 7px;
        right: 5px;
        border: 0;
        background: transparent;
        cursor: pointer;
        display: none;
      }
    }
    .TotlPatient {
      display: flex;
      justify-content: space-between;
      align-items: center;
    
      button {
        border: none;
        outline: 0 !important;
        background: none;
        cursor: pointer;
        margin: 0px 5px 0px 30px;
        color: red;
        padding: 0;
        height: unset;
      }
      h2 {
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        width: 30%;
        font-weight: 400;
        @include respond-to(media-md) {
          width: 27%;
        }
        @include respond-to(media-xs) {
          width: 100%;
        }
      }
      i {
        display: inline-block;
        vertical-align: middle;
        width: 33%;
        text-align: center;
      }
      span {
        width: 45%;
        display: inline-block;
        vertical-align: middle;
        text-align: right;
        color: #000;
        font-size: 10px;
        line-height: 18px;
        @include respond-to(media-md) {
          width: 33%;
        }
        @include respond-to(media-xs) {
          width: 40%;
        }
        h5 {
          display: block;
          font-size: 11px;
          color: #757575;
          margin-bottom: 0px;
          margin-top: 5px;
          padding: 3px;

          small {
            font-size: 11px;
            font-weight: 500;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        a {
          color: #f27863;
          font-size: 11px;
        }
      }
    }
    .comprenhHd {
      font-size: 15px;
    }
    .comparenhOuter {
      .comprenshGraph {
        text-align: center;
      }
    }
    .HccGraph {
      padding-top: 10px;
      .HccGraph_Left {
        float: left;
        width: 40%;
        // padding-top: 30px;
        @include respond-to(media-xs) {
          width: 100%;
          padding-top: 15px;
        }
        strong {
          display: block;
          font-weight: 400;
          font-size: 15px;
          padding-bottom: 7px;
        }
      }
      .HccGraph_Right {
        width: 60%;
        float: right;
        @include respond-to(media-xs) {
          width: 100%;
        }
        img {
          width: 100%;
        }
      }
    }
    .RiskGraph {
      text-align: center;
      padding-top: 10px;
    }
    .HccRisk {
      padding-top: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      .HccLeft {
        float: left;
        width: 40%;
      }
      .HccMid {
        margin-right: 50px;
        strong {
          display: block;
          padding-bottom: 10px;
          font-size: 14px;
          color: #88d479;
          cursor: pointer;
        }
        span {
          display: block;
          font-size: 14px;
          line-height: 20px;
          color: #000;
        }
      }
      .HccRight {
        strong {
          display: block;
          padding-bottom: 10px;
          font-size: 14px;
          color: #fcad60;
          cursor: pointer;
        }
        span {
          display: block;
          font-size: 14px;
          line-height: 20px;
          color: #000;
        }
      }
    }
  }
}
.DashboardOuter.cohortUpload {
  min-height: 0;
}

// .diabet_left_part {
//   @include respond-to(media-sm) {
//     display: flex;
//     flex-flow: column;
//   }
// }

.db-subtitle {
  display: block;
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.pyramidLeft {
  float: left;
  text-align: left;
  padding-left: 20px;
  padding-top: 30px;
  @include respond-to(media-md) {
    padding-left: 0px;
  }
  @include respond-to(media-xs) {
    float: none;
    padding-bottom: 15px;
  }
  h3 {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 400;
    padding-bottom: 10px;
    border-bottom: #c4c4c4 solid 2px;
  }
  h4 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    border-bottom: #c4c4c4 solid 1px;
    padding-bottom: 10px;
    padding-top: 5px;
    padding-left: 35px;
    position: relative;
    a {
      color: #3aa290;
      text-decoration: underline;
    }
    i {
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -10px;
    }
  }
  h5 {
    font-size: 11px;
    color: #757575;
    padding-top: 5px;
    line-height: 16px;

    small {
      font-size: 11px;
      font-weight: 400;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
.donut {
  display: inline-block;
  width: 35%;
  @include respond-to(media-xl) {
    width: 40%;
  }
  @include respond-to(media-xs) {
    width: 60%;
  }
}
.apexcharts-menu-icon {
  display: none;
}
.apexcharts-canvas {
  position: relative;
  user-select: none;
  width: 100% !important;
  height: auto !important;
}
.DashMidCont {
  padding: 30px 30px;
  @include respond-to(media-lg) {
    padding: 20px;
  }
  @include respond-to(media-xs) {
    padding: 20px 15px;
  }
}
.dashHd {
  text-transform: capitalize;
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 10px;
  @include respond-to(media-md) {
    font-size: 18px;
    margin-bottom: 20px;
  }
  @include respond-to(media-md) {
    font-size: 16px;
  }
  @include respond-to(media-xs) {
    font-size: 14px;
    line-height: 25px;
  }
  &.heatmap_dash {
    margin-top: 60px;
  }
  .back_img {
    margin-left: 15px;
  }
  img {
    @include respond-to(media-xs) {
      display: block;
      margin-bottom: 10px;
    }
  }

  button {
    background: transparent;
    border: 0;
    height: 30px;
    line-height: 30px;
    margin-right: 10px;
    outline: none !important;
    @include respond-to(media-xs) {
      margin-right: 5px;
      width: 18px;
      margin-left: 0;
      padding: 0;
    }
    &:focus {
      border: 0 !important;
    }
  }
}

.detailGraphOuterBx {
  background: #fff;
  border-radius: 5px;
  padding: 30px 20px;
  box-shadow: #e5e5e5 0 0 5px 0;
  .detailGraphBx {
    width: 35%;
    float: left;
    @include respond-to(media-xl) {
      width: 31%;
    }
    @include respond-to(media-lgs) {
      width: 35%;
    }
    @include respond-to(media-md) {
      width: 100%;
    }
    button {
      background: transparent;
      border: 0;
      font-size: 14px;
      font-weight: 400;
      max-width: 400px;
      margin: 0 auto;
      width: 100%;
      background: #0080b2;
      padding: 10px;
      color: #fff;
      border-radius: 3px;
      cursor: pointer;
      outline: none !important;

      @include respond-to(media-xl) {
        font-size: 12px;
      }
      @include respond-to(media-lg) {
        max-width: 320px;
      }
      @include respond-to(media-md) {
        font-size: 13px;
        margin: 0 auto 20px auto;
        max-width: 360px;
        display: block;
      }
      &:hover {
        background: #f4755f;
      }
    }
  }
  .detailTableOuterBx {
    float: right;
    width: 65%;
    @include respond-to(media-xl) {
      width: 69%;
    }
    @include respond-to(media-lgs) {
      width: 65%;
      overflow: auto;
    }
    @include respond-to(media-md) {
      width: 100%;
    }
    table {
      border: #d8d8d8 solid 1px !important;
      @include respond-to(media-lgs) {
        width: 900px;
      }
      @include respond-to(media-md) {
        width: 100%;
      }
      tr {
        th {
          background: #d8d8d8;
          color: #000;
          font-size: 13px;
          padding: 10px 20px;
          line-height: 25px;
          border: 0;
          &:nth-child(1) {
            text-align: left;
          }
        }
        td {
          font-size: 14px;
          color: #333;
          line-height: 25px;
          padding: 10px 20px;
          text-align: center;
          border: 0;
          border-bottom: #f0f4f7 solid 1px;
          font-weight: 500;
          &:nth-child(1) {
            text-align: left;
          }

          button {
            &:focus {
              outline: 0 !important;
            }
          }

          .editBtn {
            background: #fff;
            color: #0080b2;
            border: #0080b2 solid 1px;
            width: 63px;
            height: 23px;
            border-radius: 15px;
            margin-right: 10px;
            font-size: 12px;
          }
          .deleteBtn {
            background: #0080b2;
            border: #0080b2 solid 1px;
            width: 63px;
            height: 23px;
            border-radius: 15px;
            margin-right: 10px;
            color: #fff;
            font-size: 12px;
          }
        }
      }
      tr:nth-child(even) {
        background: #f6f8fa;
      }
    }
  }
}
.triangle_detail {
  position: relative;
  height: auto;
  clip-path: polygon(50% 0, 0% 100%, 100% 100%);
  width: 400px;
  @include respond-to(media-lgs) {
    width: 320px;
  }
  @include respond-to(media-md) {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
  }
}
.speedometterBx {
  // display: flex;
  // justify-content: center;
  background: #fff;
  width: 100%;
  margin: 0;
  box-shadow: #e5e5e5 0 0 5px 1px;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  position: relative;
  min-height: 350px;
  @include respond-to(media-lg) {
    margin: 15px 0 0 0;
  }
  @include respond-to(media-md) {
    margin: 15px 0 0 0;
  }
  svg.speedometer {
    height: auto !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70px);
  }
  span {
    font-size: 14px;
    position: relative;
    top: 13px;
  }
}
.diabetOut {
  @include respond-to(media-md) {
    display: block !important;
  }
}
.riskFilterOuter {
  padding-bottom: 0px;
  .riskFltrBx {
    display: inline-block;
    label {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 10px;
      font-size: 14px;
      color: #a1a4a6;
    }
    .form-control {
      display: inline-block;
      border-radius: 20px;
      padding: 0 10px;
      height: 31px;
      font-size: 14px;
      border: #d7d7d7 solid 1px;
      width: 260px !important;
      &:focus {
        box-shadow: none;
      }
    }
  }
}
.cursor-pointer{
  cursor: pointer !important;
  display: none;

  @include respond-to(media-sm) {
    display: block;
    text-align: right;
  }
}

.makeStyles-root-22 {
  width: 400px !important;
}
.diagNosOuter {
  margin-bottom: 30px;
  @include respond-to(media-md) {
    display: block !important;
  }

  .left-side {
    background: #fff;
    padding: 25px;
    border-radius: 5px;
    box-shadow: #e5e5e5 0 0 5px 1px;
    width: 70%;
    @include respond-to(media-xl) {
      padding: 20px;
    }
    @include respond-to(media-md) {
      width: 100%;
      margin-bottom: 20px;
      display: block !important;
    }
    .dignoHD {
      font-size: 16px;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 0 20px 0;

      .dashboard1-dropdown2{
        @include respond-to(media-sm){
          display: none;
        }
      }

      @include respond-to(media-sm) {
        padding-bottom: 20px;
        width: 100%;
        display: flex;
        gap: 15px;
      }
    }
    .ca-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    .diabet_left_inactive {
      width: 55%;
      position: relative;
      @include respond-to(media-lg) {
        width: 62%;   
      }
      @include respond-to(media-md) {
        width: 62%;
      }
      @include respond-to(media-sm) {
        width: 52%;
        padding-bottom: 20px;
        gap: 5px;
      }
      span.diabLabl {
        font-size: 12px;
        top: 10%;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        align-self: center;
        width: 135px;

        span.intrv-label {
          position: absolute;
          top: 10px;
        }
        
        @include respond-to(media-md) {
          font-size: 10px;
        }
        @include respond-to(media-sm) {
          font-size: 9px;
          display: flex;
          padding-top: 10px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
          white-space: nowrap;
        }
      }
      .diabMetr {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        span.diabLabl {
          display: block;
          display: -webkit-box;
          font-size: 12px;
          height: auto;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 144px;
          position: relative;
          @include respond-to(media-xl) {
            width: 120px;
          }
          @include respond-to(media-sm) {
            width: auto;
          }
          @include respond-to(media-sm) {
            font-size : 9px;
          }
          @include respond-to(media-xs) {
            font-size : 9px;
          }
          // @include respond-to(media-md) {
          //   width: 150px;
          // }
        }

        span.MuiSlider-root {
          width: 100%;
          padding: 0;
          height: 0px;
         

          .MuiSlider-rail {
            height: 30px;
            border-radius: 15px;
            background: #e8e8e8;
            opacity: 1;
          }
          .MuiSlider-track {
            height: 30px;
            background: #88d479;
            border-radius: 15px;
            
          }
          .MuiSlider-thumb {
            z-index: 12;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            border: #88d479 solid 2px;
            z-index: 2;

            
          }
          // .PrivateValueLabel-thumb-33.PrivateValueLabel-open-34 .PrivateValueLabel-offset-35 {
          //   border: 2px solid red;
          //   border-radius: 50%;
          // }
          .MuiSlider-markLabel.MuiSlider-markLabelActive:last-child() {
            right: 0 !important;
          }
        }
      }
    }

    .diabet_left_active {
      width: 55%;
      position: relative;
      @include respond-to(media-sm) {
        width: 100%;
        padding-bottom: 20px;
      }
      .diabLabl {
        position: absolute;
        width: 25px;
        left: 0;
        font-size: 12px;
        top: 10%;
      }
      .diabMetr {
        // padding-top: 10px;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        span.diabLabl {
          display: block;
          display: -webkit-box;
          font-size: 12px;
          height: auto;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 135px;
          position: relative;
          @include respond-to(media-xl) {
            width: 120px;
          }
          @include respond-to(media-sm) {
            width: auto;
          }
          @include respond-to(media-sm) {
            font-size : 9px;
          }
          @include respond-to(media-xs) {
            font-size : 9px;
          }
          // @include respond-to(media-md) {
          //   width: 150px;
          // }
        }

        span.MuiSlider-root {
          width: 100%;
          padding: 0;
          height: 0px;

          .MuiSlider-rail {
            height: 30px;
            border-radius: 15px;
            background: #e8e8e8 !important;
            opacity: 1;
          }
          .MuiSlider-track {
            height: 30px;
            background: #b7de56;
            border-radius: 15px;
          }
          .MuiSlider-thumb {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            border: #88d479 solid 2px;
            z-index: 2;
          }
          .MuiSlider-markLabel.MuiSlider-markLabelActive:last-child() {
            right: 0 !important;
          }
        }
        padding-top: 0px !important;
      }
    }

    .diabet_right {
      width: 335px;
      height: 30px;
      padding-top: 4px;
      @include respond-to(media-sm) {
        width: 100%;
      }
      @include respond-to(media-lgs) {
        width: 300px;
      }
      @include respond-to(media-md) {
        width: 250px;
      }
      @include respond-to(media-sm) {
        width: 125px;
      }

      .cost-data-message {
        font-size: 12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      table {
        border: 0px;

        th {
          border: 0px;
          height: 30px;
          font-size: 11px;
          color: #000000;
          padding: 0;
          text-align: center;
          background: transparent;
          font-weight: 500;
          padding-top: 8px;
          @include respond-to(media-lgs) {
            font-size: 10px;
          }
          @include respond-to(media-sm) {
            font-size: 7px;
          }
          @include respond-to(media-md) {
            padding-top: 0;
          }
        }
        .table-heading{
          @include respond-to(media-md) {
            padding-top: 8px;
          }
        }
        td {
          border: 0px;
          height: 25px;
          text-align: center;
          font-size: 13px;
          color: #000;
          padding: 0 10px;
          background: #e8e8e8;
          @include respond-to(media-sm) {
            font-size: 9px;
          }
          &:first-child {
            border-radius: 30px 0 0 30px;
          }
          &:last-child {
            border-radius: 0 30px 30px 0;
          }
          span {
            display: inline-block;
          }
        }
      }
      span {
        text-align: center;
        font-size: 12px;
        display: inherit;
      }
    }
  }
  .right-side {
    display: flex;
    // flex-direction: column;
    width: 28%;
    @include respond-to(media-xl) {
      width: 100% !important;
      display: block !important;
    }
  }
}

.diagNosOuter.diagNosOuter-resp {
  @include respond-to(media-xl) {
    display: block !important;
  }

  .left-side {
    @include respond-to(media-xl) {
      width: 100% !important;
    }
    .diabetOut_part {
      @include respond-to(media-sm) {
        padding-bottom: 15px;
      }
    }
    .diabetOut {
      display: flex;
      justify-content: space-between;
      @include respond-to(media-md) {
        display: flex !important;
        table {
          th {
            padding: 0px 3px;
            font-size: 12px;
            @include respond-to(media-md) {
              font-size: 9px;
            }
          }
        }
      }
    }

    .valueLeftBx {
      width: 350px !important;
      position: absolute !important;
      left: 30%;
      @include respond-to(media-md) {
        width: 270px !important;
        left: 30%;
        margin-top: -10px;
      }
      @include respond-to(media-sm) {
        width: 150px !important;
        left: 54%;
        margin-top: -32px;
      }
      @include respond-to(media-xs) {
        width: 100px !important;
      }
    }
  }
}
.incrementSaving {
  width: 20px;
  height: 5px;
  background: green;
  display: inline-block;
  border-radius: 5px;
  margin-right: 6px;
}

.decrementSaving {
  width: 20px;
  height: 5px;
  background: #ff0000;
  display: inline-block;
  border-radius: 5px;
  margin-right: 6px;
}

.mixed-chart {
  path {
    fill: #88d479;
    &:hover {
      fill: #9cd490;
    }
  }
  padding-left: 20px;
  padding-right: 20px;
}
.barchartBx {
  .apexcharts-bar-series.apexcharts-plot-series {
    .apexcharts-series:nth-child(1) {
      path {
        fill: #88d479;
        stroke-width: 1;
      }
    }
    .apexcharts-series:nth-child(2) {
      path {
        fill: #a6e7db;
        stroke-width: 1;
      }
    }
    .apexcharts-series:nth-child(3) {
      path {
        fill: #fcad60;
        stroke-width: 1;
      }
    }
    .apexcharts-series:nth-child(4) {
      path {
        fill: #f4755f;
        stroke-width: 1;
      }
    }
  }
}
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ecf0f3;
  padding: 10px;
  text-align: center;
  z-index: 1000;
  button {
    font-size: 14px;
    padding: 0 20px;
    line-height: 30px;
    margin: 0 10px;
    background: #fff;
    border-radius: 15px;
    width: 210px;
    cursor: pointer;
    outline: none !important;
    text-transform: capitalize;

    @include respond-to(media-xs) {
      font-size: 11px;
      padding: 0;
      line-height: 30px;
      margin: 0 5px;
      background: #fff;
      border-radius: 15px;
      width: 140px;
      cursor: pointer;
    }
    &:focus {
      outline: 0;
    }
  }
  .contributeBtn {
    color: #0082b2;
    border: #0082b2 solid 1px;
    &:hover {
      color: #fff;
      border: #0082b2 solid 1px;
      background: #0082b2;
    }
  }
  .interventBtn {
    color: #0082b2;
    border: #0082b2 solid 1px;
    &:hover {
      color: #fff;
      border: #0082b2 solid 1px;
      background: #0082b2;
    }
  }
}
.diabetOut {
  padding-bottom: 30px;
  @include respond-to(media-sm) {
    padding-bottom: 5px;
  }
}
.makeStyles-root-1 {
  // float: right;
  // margin-top: -22px;
  @include respond-to(media-xl) {
    width: calc(100% - 150px) !important;
  }
}
.makeStyles-root-14 {
  width: calc(100% - 220px) !important;
  float: right;
  margin-top: -22px;
  @include respond-to(media-xl) {
    width: calc(100% - 150px) !important;
  }
}

.MuiSlider-valueLabel {
  left: 0 !important;
  top: 10px !important;
  color: #fff;
  // border: 2px solid #88d479;
  // background-color: white;
  // border-radius: 50%;
  margin-left: 0px;
  position: relative;
  .PrivateValueLabel-circle-12 {
    width: 31px;
    height: 31px;
    display: flex;
    transform: none;
    align-items: center;
    // border-radius: 50%;
    justify-content: center;
    background-color: currentColor;
    
    .PrivateValueLabel-label-13 {
      color: #000;
      transform: none;
      font-size: 13px;
    }
  }
}

label.valueLeft {
  margin: 0 !important;
  float: left;
  position: relative;
  top: 32px;
  font-size: 10px;
  color: #5e5b5b;
}

label.valueMid {
  margin: 0 !important;
  // float: left;
  position: relative;
  top: 15px;
  font-size: 10px;
  color: #5e5b5b;
}

label.valueRight {
  float: right;
  top: 32px;
  position: relative;
  margin: 0;
  //   font-size: 12px;
  font-size: 10px;
  color: #5e5b5b;
  right: -3%;
}
.IntermatBtnsOuter {
  padding-bottom: 20px;
  text-align: right;
  button {
    width: 200px;
    height: 32px;
    font-size: 14px;
    margin-left: 10px;
    border-radius: 20px;
    cursor: pointer;
    outline: none !important;
  }
  .existSimBtn {
    color: #0082b2;
    border: #0082b2 solid 1px;
    background: #fff;
    margin-right: 20px;
    &:hover {
      color: #fff;
      border: #0082b2 solid 1px;
      background: #0082b2;
    }
  }
  .createSimBtn {
    color: #fff;
    border: #0082b2 solid 1px;
    background: #0082b2;
    &:hover {
      color: #0082b2;
      border: #0082b2 solid 1px;
      background: #ffffff;
    }
  }
}
.valueLeftBx {
  width: calc(100% - 220px);
  float: right;
  label.valueLeft {
    width: 5%;
    top: 25px;
  }
  label.valueMid {
    width: 90%;
    float: left;
    text-align: center;
  }
  label.valueRight {
    width: 5%;
    text-align: right;
    top: 25px;
  }
}
.valueLeftBx.contributePage {
  position: relative;
  top: -10px;
}

.segment-value {
  font-weight: normal !important;
}

.current-value {
  font-weight: normal !important ;
}

.progress {
  height: 10px;
  border-radius: 80px;
}

.progress-bar {
  color: black;
}

.bg-success {
  background-color: #88d479 !important;
}
.progressOut {
  position: relative;
  top: 12px;
}

.top-widget {
  button {
    top: -26px !important;
    img{
      top: 2px;
      left: 15px;
    }
  }
  
}

.valueOuter {
  position: relative;
  button {
    position: absolute;
    right: -25px;
    // margin-top: -40px;
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    top: 20px;
    width: 15px;
    opacity: 0.7;
    outline: none !important;
  }
}
.tableviewOuter {
  table.table {
    border: 0 !important;
    th {
      border: 0 !important;
      font-size: 14px;
      padding: 10px;
      line-height: 20px;
      height: auto;
      text-align: center;
    }
    td {
      border: 0 !important;
      font-size: 13px;
      padding: 10px;
      line-height: 20px;
      height: auto;
      border-bottom: #e5e5e5 solid 1px !important;
      text-align: center;
    }
  }
}
.modalHd {
  text-align: left !important;
  position: relative;
  img {
    // position: absolute;
    right: 0;
    top: 0;
  }

  .modalHdSim {
    text-align: center !important;
    position: relative;
    img {
      // position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.modalIcons {
  float: right;
  .iconPrint {
    position: relative;
    right: 30px;
  }
}

.MuiTooltip-tooltip {
  color: red;
}

.MuiTooltip-tooltipArrow {
  color: red;
}

.MuiTooltip-arrow {
  height: 30px;
  color: red;
}

.overrideDial {
  position: relative;
  left: 180px;
  top: 26px;
  z-index: 999;
  background: #fff;
}

img.closemodal {
  width: 18px;
  cursor: pointer;
}

.arrow_box {
  padding: 10px;
}

.arrow_box2{
  padding: 10px;
  min-width: 250px !important;
  background-color: #0080b2;
  color: #fff;
}

.arrow_box3{
  padding: 10px;
  min-width: 350px !important;
  background-color: #0080b2;
  color: #fff;
}

.updateBtn {
  color: #3aa290 !important;
  text-decoration: underline;
  width: unset;
}

ul {
  list-style-type: none;
  margin: 0 !important;
  padding: 0;
}

.pt-left {
  background-color: #fff;
  border-radius: 0.2rem;
}
.pt-right {
  background-color: #fff;
  border-radius: 0.2rem;
}
.pt-left h3 {
  font-size: 18px;
  border-bottom: 1px solid #e7e7e7;
  padding: 1.5rem;
  color: #333;
  font-weight: 400;
  margin-bottom: 0;
}
.pt-right h3 {
  font-size: 22px;
  border-bottom: 1px solid #e7e7e7;
  padding: 1.5rem;
  color: #333;
  font-weight: 400;
  margin-bottom: 0;
}
.pt-drop {
  padding: 1.5rem;
  border-bottom: 1px solid #e7e7e7;
}
.pt-drop h4 {
  font-size: 16px;
  letter-spacing: 0.5px;
}
.dwn-arrow img {
  transform: rotate(180deg);
  width: 12px;
}
.drop-list label {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 26px;
}
.drop-list ul li {
  margin-bottom: 5px;
}
.drop-list ul li:last-child {
  margin-bottom: 0;
}
.drop-list ul li:first-child {
  margin-top: 5px;
}
.filetr-by {
  padding: 1.5rem;
}
.filetr-by h4 {
  color: #aaa;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.5px;
  margin-bottom: 1rem;
}

.filetr-by .form-control {
  width: 200px;
  height: 32px;
  font-size: 14px;
  border-radius: 20px;
}

.db-table-ar {
  overflow: hidden;
  margin-top: 10px;
}
.table-light {
  background-color: #fff;
}
.table-light thead th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 20px;
}
.table-light thead th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 20px;
}
.table-light thead th {
  background-color: #eff4f8;
  color: #9d9ea2;
  font-weight: 600;
  font-size: 14px;
  border-color: transparent !important;
  text-align: center;
  padding: 0.65rem;
}
.table-light tbody td {
  border: 0;
  color: #000;
  font-size: 14px;
  text-align: center;
  padding: 0.65rem;
}

.table-light .patient-table-th {
  vertical-align: middle !important;
}

.table-light .patient-list {

  &:nth-child(even) {
    background-color: #f2f2f2;
  }

  &:nth-child(odd) {
    background-color: white;
  }

  td {
    padding: 0.35rem 0.65rem;
    vertical-align: middle;
  }
}

.table-light a {
  color: #0082b2 !important;
}
.patient-id {
  color: #0082b2 !important;
  cursor: pointer;
}
.patient-id:hover {
  text-decoration: underline;
}

.md-body .tb-area {
  padding: 10px 1.5rem;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}

.clr1 {
  color: #99cbc0;
  font-size: 15px;
  font-weight: 700;
}
.clr2 {
  color: #ffd3a9;
  font-size: 15px;
  font-weight: 700;
}
.clr3 {
  color: #518fd2;
  font-size: 15px;
  font-weight: 700;
}
.md-txt {
  display: block;
  font-size: 14px;
  font-weight: 400;
}

table thead th {
  vertical-align: top !important;
}

.tb-area table tr td {
  border: 0;
}

.tb-area table tr td button {
  outline: none !important;
  color: #0082b2;
  background-color: white;
  border: #0082b2 solid 1px;
}

.tb-area table tr td button:hover {
  outline: none !important;
  color: #fff;
  background-color: #0082b2;
  border: #0082b2 solid 1px;
}

.db-table-ar .custom-control-label::before {
  top: -0.75rem;
}

// .pt-data.patient-viewwrappers {
//   padding-top: 0px;
// }

.filetr-by .tblBtn {
  margin-top: 22px;
}
.modifiers-section .tblBtn {
  margin: 0;
}
.patient-viewwrappers .tblBtn button {
  margin-left: 15px;
  color: #0082b2;
  border: #0082b2 solid 1px;
  font-size: 14px;
  padding: 0 20px;
  line-height: 30px;
  margin: 0 10px;
  background: #fff;
  border-radius: 15px;
  width: 210px;
  cursor: pointer;
  outline: none !important;
  text-transform: capitalize;
}

.patient-viewwrappers .tblBtn button:hover {
  // margin-left: 15px;
  outline: none !important;
  color: #fff;
  background-color: #0082b2;
}
.modifiers-section .tblBtn button:hover {
  outline: none !important;
  color: #fff;
  background-color: #0082b2 !important;
} 

.export-Btn {
  margin-left: 15px;
  color: #0082b2;
  border: #0082b2 solid 1px;
  font-size: 14px;
  padding: 0 20px;
  line-height: 30px;
  margin: 0 10px;
  background: #fff;
  border-radius: 15px;
  width: 250px;
  cursor: pointer;
  outline: none !important;
  text-transform: capitalize;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  display: flex,
}

.export-Btn:hover {
  // margin-left: 15px;
  outline: none !important;
  color: #fff;
  background-color: #0082b2;
  text-decoration: none;
}

 .export-container {
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
  margin-top: 25px;
 }

.accordian-option-box {
  margin-bottom: 30px;
}
.makeStyles-paper-2.patient-view-modal {
  padding: 16px 0px 24px;
}
.patient-view-modal table {
  border: 0px;
}

.patient-view-modal {
  max-height: 90vh;
  .spinner {
    margin: 40px 0px;
  }
}

.MuiBox-root {
  padding: 0px !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #007bff !important;
}

.patient-topDetails tbody tr td {
  border-color: #e0e0e0;
  border-left: 0;
  border-right: 0px !important;
  padding: 8px 8px 8px 25px !important;
  text-transform: uppercase;
}
.patient-view-modal table tr td {
  text-align: left;
  padding: 7px;
}
.patient-view-modal .detail-area {
  max-height: 65vh;
}

.makeStyles-root-1 {
  float: right;
}

.MuiAccordionDetails-root {
  display: flex;
  padding: 0px 16px !important;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 5px;
}

.explore-data {
  text-align: end;
}

.explore {
  background: #fff;
  width: 200px;
  height: 32px;
  font-size: 14px;
  border: #0082b2 solid 1px;
  color: #0082b2;
  border-radius: 20px;
  outline: none !important;
  cursor: pointer;
}

.explore:hover {
  color: #fff;
  border: #0082b2 solid 1px;
  background: #0082b2;
}

.MuiTypography-body1 {
  font-size: 14px !important;
}

.modifiers-section {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  position: relative;
  padding-left: 80px;

  .modifier-sm-title {
    position: absolute;
    left: 16px;
    top: 8px;
  }

  h6 {
    color: #0009;
  }

  ul {
    display: table;
    padding: 3px;
  }

  ul li {
    display: inline-block;
    margin: 0px 8px;
  }

  button {
    background: none !important;
    border: 0;
    color: #000;
    border-radius: 30px;
    padding: 0px !important;
    font-size: 14px;
    cursor: pointer;
    outline: none !important;
  }

  .save-filters {
    color: #0082b2 !important;
    border: #0082b2 solid 1px !important;
    background: #fff !important;
  }

  .save-filters:hover {
    color: #fff !important;
    border: #0082b2 solid 1px !important;
    background: #0082b2 !important;
  }

  .inner-tag {
    padding: 6px 10px 0px 0px;
    color: #000 !important;
    border-right: 1px solid;
    border-right-color: #ced4da;
  }

  .inner-tag-sort {
    color: #0082b2 !important;
    padding: 6px 0px;
  }
}

.inner-wrapper {
  display: flex;
  margin-left: 8px;
  margin-right: 5px;
  border: 1px solid #ced4da;
  border-radius: 50px;
  padding: 0px 20px;
  margin-bottom: 10px;

  img {
    width: 12px;
    margin-left: 4px;
    cursor: pointer;
  }

  .modifier-sm-title {
    font-size: 14;
    // position: relative;
    // top: 8;
    position: absolute;
    left: 0;
    top: 8px;
  }

  ul li {
    color: #0082b2 !important;
  }
}

.makeStyles-heading-2 {
  font-size: 14px !important;
}

.sort-icons {
  img {
    width: 8px;
    cursor: pointer;
  }
}

.headings-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.dropdown-list {
  width: auto !important;

  option {
    line-height: 50px !important;
  }
}

.makeStyles-container-7 {
  position: relative;
  bottom: 18px !important;
}

button {
  // background: #0082b2;
  width: 200px;
  height: 32px;
  border-radius: 20px;
  outline: none !important;
  cursor: pointer;
}

button.backToPatientSet {
  color: #0082b2;
  border: #0082b2 solid 1px;
  font-size: 14px;
  line-height: 30px;
  margin: 0 10px;
  background: #fff;
  border-radius: 15px;
  cursor: pointer;
  outline: none !important;
  text-transform: capitalize;
}

button.backToPatientSet:hover {
  outline: none !important;
  color: #fff;
  background-color: #0082b2;
}


.dropdown-item {
  border-radius: 0px !important;
  border: none !important;
  color: #000;
}

.dropdown-item:active {
  background-color: #ced4da !important;
  outline: none !important;
}

dropdown-item:hover {
  background-color: #2c094625 !important;
  outline: none !important;
}

.dropdown-toggle {
  text-align: left;
  font-size: 13px !important;
}

.dropdown-toggle:hover {
  color: #495057 !important;
  border: 1px solid #ced4da !important;
}

.dropdown-toggle::after {
  margin-left: 120px;
}

.dynamic-cohort {
  padding: 10px;
  font-size: 18px;
  color: #b3b6b7;
  width: 100%;
}

.dynamic-cohort:focus {
  color: #333;
}

.valueOuter button:hover {
  border-color: transparent;
}

.name-of-cohartWrap {
  display: flex;
  // padding-top: 20px;
  padding-bottom: 20px;
  align-self: center;
  align-items: center;
}

.noc-label {
  font-size: 18px;
  color: #333;
  font-weight: 400;
  position: relative;
  bottom: 2px;
}

.noc-inputfield {
  width: 100%;
  max-width: calc(100% - 155px);
  padding-left: 0px;
}

.noc-inputfield .dynamic-cohort {
  border: transparent;
}
.noc-inputfield .dynamic-cohort:focus {
  outline: 1px;
  border: 1px solid #2e82b1 !important;
  border-radius: 4px !important;
  margin-left: 8px;
}
.noc-bottomline {
  border-bottom: 1px solid #ced4da;
}

.name-of-cohart-SecWrap {
  padding: 15px 20px 20px;
}

.MuiCollapse-wrapperInner .PrivateSwitchBase-root-3 {
  padding: 3px 9px !important;
}

.filetr-by .filter-dropdown-field-wrapper .form-control,
.filetr-by .filter-dropdown-field-wrapper button {
  width: 100%;
}
.filter-dropdown-field-wrapper .dropdown-toggle {
  position: relative;
}
.filter-dropdown-field-wrapper .dropdown-toggle::after {
  margin-left: 0;
  position: absolute;
  right: 20px;
  top: 15px;
}

.filter-dropdown-field-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-right: 0px;
  margin-left: 0px !important;
  margin-top: -5px !important;
}
span{
  @include respond-to(media-sm) {
    width: 50%;
    padding: 0;
  }
}

.filtrDropdown {
  display: flex;
  margin-left: 30px;
}
.makeStyles-paper-17 {
  @include respond-to(media-lg) {
    width: 600px !important;
    height: 550px !important;
    overflow: auto;
  }
}
.makeStyles-paper-8 {
  @include respond-to(media-lg) {
    height: 550px !important;
    overflow-y: auto;
  }
}

.graph-wrapper {
  .pyramidLeft {
    @include respond-to(media-lgs) {
      width: 50%;
    }
    h3,
    h4,
    h5 {
      br {
        @include respond-to(media-lgs) {
          display: none;
        }
      }
    }
  }
  .triangle_ov {
    @include respond-to(media-lgs) {
      width: 50%;
    }
  }
}

.PrivateSwitchBase-root-20 {
  padding: 0px 9px !important;
}

label + .MuiInput-formControl {
  margin-top: 6px;
}

.error-area {
  color: red;
  text-align: center;
}

.submit-form-btn {
  margin-left: 15px;
  outline: none !important;
  color: #0082b2;
  background-color: white;
  border: #0082b2 solid 1px;
}

.submit-form-btn:hover {
  margin-left: 15px;
  outline: none !important;
  color: #fff;
  background-color: #0082b2;
}

.active-input-simulation {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  align-items: center;

  input {
    margin: 0px 10px;
    border-radius: 30px;
    outline: none;
    width: 250px;
    padding: 8px;
    border: 1px solid #ced4da;
    color: #495057;
  }
}

.simulation-btn {
  // display: flex;
  // justify-content: flex-end;
  // width: 100%;
  .simulation-save {
    margin-right: 30px;
    margin-top: 2px;
    margin-bottom: 2px;
  }
}
.simulation-btn button {
  color: #0082b2;
  border: #0082b2 solid 1px !important;
  background: #fff !important;
  width: 120px;
  font-size: 14px;
  line-height: 30px;
}
.simulation-btn button:hover {
  color: #fff;
  border: #0082b2 solid 1px;
  background: #0082b2 !important;
}
.inactive-input-simulation {
  margin-top: 25px;
}

.inactive-input-simulation button {
  float: right;
  position: relative;
  top: 180px;
}

.emulationOuter {
  padding: 15px 50px 60px 50px;
  margin-top: 0px;
  @include respond-to(media-md) {
    padding: 15px 40px 60px 40px;
  }
  @include respond-to(media-xs) {
    padding: 15px 20px 60px 20px;
  }
}

.popValue {
  position: absolute;
  bottom: 30px;
  text-align: center;
  right: 0;
  left: 0;
  font-size: 14px;
  @include respond-to(media-xl) {
    bottom: 30px;
  }
}

.filter-checkbox {
  margin: 0px 10px 10px 0px;
  cursor: pointer;
}

.MuiAccordionDetails-root {
  font-size: 14px;
}

.MuiAccordionDetails-root input:indeterminate {
  box-shadow: 0 0 1px 6px red inset;
  position: relative;
  overflow: hidden;
  z-index : 2;
  min-width: 13px;
  height: 13px;
  top: 2px;
}
.MuiAccordionDetails-root input:indeterminate:after {
  color: #ffffff;
  content: "X";
  position: absolute;
  left: 3px;
  top: 1px;
  height: 1px;
  font-size: 10px;
  font-weight: 600;
}
.progressOut .progress-bar.bg-success {
  position: relative;
  z-index: 2;
}

.diabet_left_inactive .bg-active {
  span.MuiSlider-root {
    position: absolute;
    top: 27px;
  }
  .valueLeftBottom,
  .valueRightBottom {
    top: 44px;
  }
}

.diabet_left_active .bg-active {
  .MuiSlider-track {
    background: #ffec076b !important;
  }
  span.MuiSlider-root {
    position: absolute;
    top: 27px;
  }
  span.diabLabl {
    position: absolute;
    width: 25px;
    left: 0;
    font-size: 12px;
    top: 10%;
  }
}

.diabet_left_active .bg-active {
  .valueLeftBottom,
  .valueRightBottom {
    top: 44px;
  }
}

.valueLeftBottom {
  position: absolute !important;
  top: 35px;
  left: 0;
  margin: 0 !important;
  float: left;
  font-size: 10px;
  color: #5e5b5b;
}

.valueRightBottom {
  position: absolute !important;
  top: 35px;
  right: 0;
  margin: 0 !important;
  float: left;
  font-size: 10px;
  color: #5e5b5b;
}

.progress {
  height: 30px;
}

// Segmented Slider Classes

.SlideOuterBx {
  text-align: left !important;
  width: 325px;
  float: right;
  position: relative;
  @include respond-to(media-md) {
    width: 250px;
  }
  @include respond-to(media-sm) {
    width: 130px;
  }
  @include respond-to(media-xs) {
    width: 100px;
  }
}

.slideInpOuter {
  width: 100%;
  position: relative;
  height: 0px;
  z-index: 9;
}

.slidestepOuter {
  width: 100%;
  top: -39px;
  position: relative;
  line-height: 29px;
}

.slidestepOuter span {
  display: inline-block;
  text-align: center;
  color: #fff;
}

.slidepopOuter button {
  cursor: pointer;
  background: transparent !important;
  border: 0;
  padding: 0;
  position: relative;
  top: 0px;
  font-size: 10px;
  height: 21px;
  width: 100%;
}

.slidepopOuter button:focus {
  cursor: pointer;
  border-color: transparent !important;
  box-shadow: none !important;
}
.value {
  font-size: 24px !important;
  line-height: 25px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  top: -3px;
  color: #333;
}
.slidestepOuter span:first-child {
  border-radius: 15px 0 0 15px;
}

// .slidestepOuter span:last-child {
//   border-radius: 15px 0 0 15px;
// }
.slide1 {
  background: #f4755f;
  cursor: pointer;
}
.slide2 {
  background: #f4a353;
  cursor: pointer;
}
.slide3 {
  background: #79d4c5;
  cursor: pointer;
}
.slide4 {
  background: #87d478;
  cursor: pointer;
}
.slide5 {
  background: #3390BA;
  cursor: pointer;
}
.slide6 {
  background: #800080;
  cursor: pointer;
}
.slide7 {
  background: #A52A2A;
  cursor: pointer;
}
.slide8 {
  background: #808080;
  cursor: pointer;
}
.slide9 {
  background: #FFC0CB;
  cursor: pointer;
}
.slide10 {
  background: #FFFF00;
  cursor: pointer;
}

.tooltipOuter {
  position: relative;
}
.tooltipBg {
  background: #000;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  max-width: 250px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.filterBx {
  display: flex;
}

input[type="range"] {
  --thumbSize: 35px;
  --trackSize: 28px;
  // --thumbBg: #fff;
  --trackBg: #e8e8e8;
  --progressBg: #88d479;

  /* webkit progress workaround */
  --webkitProgressPercent: 0%;
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: var(--thumbSize);
  width: 100%;
  margin: 0;
  padding: 0;
}
input[type="range"]:focus {
  outline: none;
}

/* Thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: 50%;
  // border: 2px solid #88d479;
  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
  z-index: 999;
}

input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: 50%;
  // border: 2px solid #88d479;
  // box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
  z-index: 999;
}

input[type="range"]:hover::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: 50%;
  // border: 2px solid yellow;
  // box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
  z-index: 999;
}

input[type="range"]::-ms-thumb {
  -ms-appearance: none;
  appearance: none;
  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: calc(var(--thumbSize) / 2);
  // border: 2px solid #88d479;
  // box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
  z-index: 999;
}

input[type="range"]:hover::-ms-thumb {
  -ms-appearance: none;
  appearance: none;
  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: calc(var(--thumbSize) / 2);
  border: 2px solid yellow;
  // box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
  z-index: 999;
}

/* Track */
input[type="range"]::-webkit-slider-runnable-track {
  height: var(--trackSize);
  background-image: linear-gradient(
    90deg,
    var(--progressBg) var(--webkitProgressPercent),
    var(--trackBg) var(--webkitProgressPercent)
  );
  border-radius: calc(var(--trackSize) / 2);
}
input[type="range"]::-moz-range-track {
  height: var(--trackSize);
  background-color: var(--trackBg);
  border-radius: calc(var(--trackSize) / 2);
}
input[type="range"]::-ms-track {
  height: var(--trackSize);
  background-color: var(--trackBg);
  border-radius: calc(var(--trackSize) / 2);
}

/* Progress */
input[type="range"]::-moz-range-progress {
  height: var(--trackSize);
  background-color: var(--progressBg);
  border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
}
input[type="range"]::-ms-fill-lower {
  height: var(--trackSize);
  background-color: var(--progressBg);
  border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
}
// .customize_progressbar {
//   position: absolute;
  
// }
.customize_progressbar_icon {
  position: absolute;
  margin: 0;
  padding: 0;
  top: 0px;
  width: 35px;
  height: 35px;
  color: #000;
  font-size: 11px;
  line-height: 35px;
  text-align: center;
  pointer-events: none;
  background: transparent;
  text-align: center;
  z-index: 9;
  background: #fff;
  border: #88d479 solid 2px;
  border-radius: 50%;
}

output {
    display: inline-block;
    // border: 2px solid #88d479;
    // border-radius: 50%;
    width: 35px;
    height: 35px;
    position: relative;
    top: -2px;
    left: -2px
}


.dignosValueOuter {
  @include respond-to(media-md) {
    width: 240px;
  }
  @include respond-to(media-sm) {
    width: 140px;
  }
  @include respond-to(media-xs) {
    width: 90px;
  }
  button {
    top: 27px;
  }
}

.segmntvalue {
  position: absolute;
  font-size: 10px;
  left: 17px;
  top: 36px;
  span {
    display: inline-block;
    padding-right: 5px;
    margin-right: 5px;
    border-right: #000 solid 1px;
    line-height: 12px;
    &:last-child {
      border-right: 0;
      padding-right: 0px;
      margin-right: 0px;
    }
  }
}

.dsrc1 {
  color: #f4755f;
  cursor: pointer;
}
.dsrc2 {
  color: #f4a353;
  cursor: pointer;
}
.dsrc3 {
  color: #79d4c5;
  cursor: pointer;
}
.dsrc4 {
  color: #87d478;
  cursor: pointer;
}
.dsrc5 {
  color: #3390BA;
  cursor: pointer;
}
.dataCheckbox {
  display: inline-block;
  margin-right: 5px;
  margin-top: 3px;
}

.date-bold {
  font-weight: bold;
  font-style: normal;
}

.note-source {
  font-style: normal;
  font-size: 14px !important;
  font-weight: bold;
}

.note-txt {
  font-size: 15px !important;
  display: block;
  font-weight: 400;
  font-style: italic;
}

.mark-bold {
  font-style: italic;
  background-color: yellow;
}

.mark-dismissed {
  font-style: italic;
  background-color: #ff4f4f;
}

.btn-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px 0px 10px 0px;
}

.patient-view-modal .btn-notes {
  width: 155px;
  height: 45px;
  outline: none !important;
  color: #0082b2;
  background-color: white;
  border: #0082b2 solid 1px;
}

.patient-view-modal .btn-notes:hover {
  outline: none !important;
  color: #fff;
  background-color: #0082b2;
  border: #0082b2 solid 1px;
}

.btn-disabled:disabled {
  background-color: #EBEBE4;
}

.scrollable-div {
  height: 220px;
  overflow: scroll;
  scroll-behavior: smooth;
}
.scrollable-div-2 {
  height: 75vh;
  overflow: scroll;
  scroll-behavior: smooth;
}

.scrollable-div  table td{
padding: 20px;
border: 1px solid #dedede !important;
vertical-align: top;
}

.scrollable-div-2  table td{
  padding: 20px;
  border: 1px solid #dedede !important;
  vertical-align: top;
  }

.scroll-area {
  overflow-y: scroll;
  height: 60vh;
  position: relative;
}

.MuiButtonBase-root {
  width: unset !important;
}

.dropdown-1 {
  display: flex;
  justify-content: center;
}

.dropdown-2 {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.MuiFormLabel-root.Mui-focused {
  color: #007bff !important;
}

.left-side-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 15px;

  .dignoHD {
    padding-bottom: unset !important;
  }
}

.left-side-top-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.data-source-filter {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #a1a4a6;
  margin-bottom: 0.5rem;

  label {
    margin-bottom: 0px !important;
    color: #000;
  }

  .dataCheckbox {
    margin-top: 0px !important;
  }
}

.dashHd img {
  cursor: pointer;
}

.riskFltrBx {

  .form-group {
    margin-bottom: 0px;
  }
  label {
    color: #000 !important;
    font-size: 13px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .filter-dropdown-field-wrapper {
    margin-top: unset !important;
  }
  .form-control {
    width: 200px !important;
  }
}

.MuiSelect-select:focus {
  background-color: unset !important;
}

.center-side {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: #e5e5e5 0 0 5px 1px;
  width: 100%;
  height: 120px;
  @include respond-to(media-xl) {
    padding: 20px;
  }
  @include respond-to(media-md) {
    width: 100%;
    margin-bottom: 20px;
    display: block !important;
  }
}

.add-icon-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  img {
    width: 85px;
    cursor: pointer;
  }
}

.patient-set-title {
  color: #9d9ea2;
  font-weight: 600;
  font-size: 14px;
  align-self: center;
  text-transform: capitalize;

  @include respond-to(media-sm) {
    font-size: 10px;
  }
}

.tooltip-container {
  width: 350px !important;
}

.mui-tooltip {
  font-size: 14px;
  padding: 5px;
  display: inline-block;
  text-align: center;
  width: 100%;
}

.modifier-columns {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #eff4f8;
  color: #fff !important;
}

.cancel-icon {
  font-size: 1rem !important;
  color: rgba(0, 0, 0, 0.26) !important;
  margin-left: 5px;
  vertical-align: sub;
  cursor: pointer;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
  padding: 5px !important;
}

span.MuiAutocomplete-tag {
 border: none !important;
}

.column-filter-tooltip {
  font-size: 14px;
}

.pointer-events {
  pointer-events: auto !important;
}

// .filter-three-dots {
//   position: relative;
// }

.filter-three-dots .MuiIconButton-root .MuiIconButton-label .MuiSvgIcon-root {
  font-size: 1.1rem !important;
}

.menu-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-flex-start {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.column-menu-item {
  height: 32px !important;
}

.filter-icons {
  align-self: center;
}

.btn-close {
  width: 145px;
  height: 30px;
  font-size: 14px;
  outline: none !important;
  color: #0082b2;
  background-color: white;
  border: #0082b2 solid 1px;
  margin-bottom: 5px;

  &:hover {
    outline: none !important;
    color: #fff;
    background-color: #0082b2;
    border: #0082b2 solid 1px;
  }
}

.MuiAlert-icon {
  margin-right: 4px !important;
}

.spinner {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.swal-footer {
  display: flex;
  justify-content: center;
}

.swal-icon--error {
  border-color: #7cd1f9;
}

.swal-icon--error__line {
  background-color: #7cd1f9;
}

.swal-text {
  text-align: center;
  background-color: rgb(255, 244, 229);
  font-size: 14px;
  padding: 9px;
  max-width: calc(100% - 35px);
  pointer-events: all;
}

.swal-title {
  font-size: 22px;
}

.patient-set-body {
  .btn-modal {
    width: 155px;
    height: 33px;
    margin: 20px 10px 10px 10px;
    outline: none !important;
    color: #0082b2;
    background-color: white;
    border: #0082b2 solid 1px;
  
    &:hover {
      outline: none !important;
      color: #fff;
      background-color: #0082b2;
      border: #0082b2 solid 1px;
    }

    &:disabled {
      color: grey;
      border: grey solid 1px;

      &:hover {
        color: #fff;
        background-color: grey;
        border: grey solid 1px;
      }
    }
  }

  .btn-modal-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
} 

.modal-input {
  width: 52%;
  height: 32px;
  font-size: 14px;
  border-radius: 20px;
}

.MuiAlert-filledSuccess {
  font-weight: 600 !important;
  font-size: 16px !important;
  background-color: #3e93bc !important;
}

.table-responsive {
  overflow: auto !important;
}

.auto-scroll {
  height: 70%;
  overflow-y: auto;
}

.table-icon-1 {
  font-size: 20px !important;
  margin-top: 2px;
}

.table-icon-2 {
  font-size: 19px !important;
}

.table-icon-3 {
  font-size: 18px !important;
}

.table-btn-span {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin: 0px 5px 0px 0px;
  }
}

.toaster .MuiAlert-root {
  background-color: #fff !important;
}

.toaster .MuiAlert-outlinedSuccess .MuiAlert-icon {
  color: #0082b2 !important;

  .MuiSvgIcon-root {
    fill: #0082b2 !important;
  }
}

.MuiAlert-outlinedSuccess {
  border: 0px solid #fff !important;
}

.click-here {
  color: #0082b2;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.faint-txt {
  font-size: 14px;
  color: rgb(219, 208, 208);
}

.flex-end {
  display: flex;
  width: 100%;
  justify-content: center;
}

.lighten-text {
  color:#6c757d;
  font-size: 22px;
}

.click-hover {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.pn-unavailable {
  width: 100%;
  text-align: center;
  display: block;
  font-size: 16px;
  padding: 40px 0px 40px 0px;
}

.MuiAccordionDetails-root {
  word-break: break-word !important;
}

.last_updated {
  text-transform: capitalize;
  margin-left: 25px;
  line-height: 15px;
  color: #9d9ea2;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px;

  .last_updated_span1 {
    font-size: 13px;
  }

  .last_updated_span2 {
    font-size: 13px;
    color: #9d9ea2;
    font-weight: 600;
  }
}

.MuiCheckbox-colorSecondary.Mui-disabled {
  color: #6c757d !important;
}

.tooltip-table-outer {
  .md-txt {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.tooltip-table {
  width: 355px !important;
  margin: 15px 0px 15px 0px !important;
  padding: 10px;
  color: #fff;
}

.tooltip-table tr td {
  background-color: transparent !important;
  font-size: 14px;
  white-space: nowrap;
}

.tooltip-table tr th {
  background-color: transparent !important;
  position: unset !important;
  font-size: 14px;
}

.recommended-table {
  width: 100% !important;
  margin-top: 20px;
}
.makeStyles-paper-22 {
  // height: auto !important;
  overflow: auto;
}

.lighten-txt {
  font-size: 12px;
  // white-space: nowrap;
  margin: 5px 0px 8px 0px;
  display: block;
}

.riskFilterOuter .flexGroup {
  display: flex;
  // flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  @include respond-to(media-sm) {
    flex-wrap: wrap;
  }
}

.flexDivide {
  display: flex;
  flex-flow: column;
  .dignoHD {
    width: 100%;
  }
  .flexGroup {
    width: 100%;
  }
}

.lighten-txt-title {
  display: block;
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.riskFilterOuter .flexInner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.valueLeftBx .grey-txt {
  color: #a0a0a9 !important;
}

.filterLabel {
  color: #000 !important;
  font-size: 13px !important;
  margin-right: 17px;
  width: 45px;
  @include respond-to(media-md) {
    margin-right: 0;
  }
  @include respond-to(media-xs) {
    font-size: 10px !important;
  }
  align-self: center;
}

.patientInrvSelect .MuiSelect-select.MuiSelect-select {
  width: 110px;
  font-size: 14px;
}

.menu-flex-start {

  .table-column-title {
    padding: 0 5px;
  }

  .table-column-icons {
    width: auto;
    display: flex;
    align-items: center;

    .three-dots-icon {
      font-size: 20px !important;
      cursor: pointer;
    }
    
    .chart-icon {
      font-size: 20px !important;
      cursor: pointer;
      // color: #0080b2;
    }
  }
}

.stats-modal-popper {
  padding: 15px;
  width: auto;
  min-width: 600px;
  max-width: 100vw;
  height: auto;
  min-height: 450px;
  max-height: 800px;
}

.stats-histogram-container {
  display: flex;
  justify-content: center;
  padding: 0px 10px 10px 10px;

  .stats-histogram {
    width: auto;
  }
}

.flex-sb {
  display: flex;
  justify-content: space-between;
}

.close-icon {
  width: 17px;
  cursor: pointer;
}

.inactive-patients-outer {
  padding: 15px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  span {
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.00938em;
  }
}

.clear-icon-pl {
  width: 20px !important;
  color: #656979 !important;
}

.blurry-text {
  color: transparent;
  text-shadow: 0 0 14px rgba(0,0,0,0.5);
}

.aa-Panel {
  z-index: 10000;
} 
.aa-Panel--scrollable{
  overflow-y: scroll !important;
  max-height: 150px !important;
}
.aa-ClearButton {
  width: unset;
  height: unset;
}

.aa-Form {
  border-radius: 20px !important;
  border: 2px solid #ced4da !important;
}

.aa-Form:focus-within {
  border: #fff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25) !important;
}

.aa-Label svg, .aa-LoadingIndicator svg {
  color: #518fd2 !important;
  margin-top: 4px;
}

.mark, mark {
  padding: 0 !important;
}

label.checkpointValue{
  margin: 0 !important;
  float: left;
  position: relative;
  top: 32px;
  font-size: 10px;
  color: #5e5b5b;
  left: 79%;
}
div.brokenAxis{
  background: white;
  width: 5%;
  height: 30px;
  position: absolute;
  left: 90%;
  top: 2px;
 
  }
  .chart__container{
    padding: 0px 58px 60px 58px;
    margin-top: 30px;
  }
 
 .menu__uuid{
   display: flex;
   flex-direction: column; 
 }
.dropdown__container{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  overflow: hidden;
}

.flexGroup .dropdown__container{
  width: 50%;
}

.header__menu .MuiButtonBase-root {
  user-select: text !important;
  }
  div#demo-mutiple-name {
    padding: 10px 20px;
  }
  .multiple-select.MuiInputBase-formControl::before {
    content: initial;
  }
  .multiple-select.MuiInput-underline.Mui-focused::after {
    border-bottom: none;
  }
  
.leaflet-top, .leaflet-bottom {
  position: absolute;
  z-index: 999 !important;
  pointer-events: none;
  top: 15px !important;
}

.fab_icon {
  position: fixed;
  bottom: 12px;
  z-index: 1000;
  width: 100% !important;
  text-align: right;
  right: 12px;
}

button.MuiButtonBase-root.MuiFab-root.MuiFab-primary {
  width: 50px !important;
  height: 50px;
}

.MuiFab-label img{
  max-width: 100%;
  max-height: 100%;
  position: relative !important;
  right: 0 !important;
  width: 20px;
}

.filter_header h3 {
    font-size: 1.75rem;
    background-color: #0984BA;
    color: white;
    padding: 10px;
}

.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
  width: 230px;
    max-width: 250px;
    margin: 30px 10px;
}

.filter_dropdown .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #0984BA !important;
}

li#combo-box-demo-option-4 {
  overflow-x: hidden;
}

.filter_dropdown .multiple_dropdown {
  width: 230px;
  border: 1px solid #C4C4C4;
  border-radius: 4px;
  height: 56px;
  margin: 24px 10px 0 10px;
}

.MuiInput-underline:hover:not(.Mui-disabled):before,
.MuiInput-underline:not(.Mui-disabled):before
 {
  border-bottom: none !important;
}

.MuiInput-underline:after {
  border-bottom: none !important;
}

.MuiTablePagination-toolbar {
  margin-top: 16px;
}

.filterSearchBox{
  position: absolute;
  width: 100%;
  padding: 0px 16px 0px 10px;
  z-index:3;
  .searchInput{
    font-size: 14px;
    height: 100%;
    width: 100%;
  }
}

.zigzagline::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 12px;
  top: 110%;
  background: linear-gradient(-135deg, #88d479 8px, transparent 0) 0 8px, linear-gradient(135deg, #88d479 8px, transparent 0) 0 8px;
  background-position: top left;
  background-repeat: repeat-x;
  background-size: 6px 16px;
}
.zigzagline::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 16px;
  top: 100%;
  background: linear-gradient(-135deg, #fff 8px, transparent 0) 0 8px, linear-gradient(135deg, #fff 8px, transparent 0) 0 8px;
  background-position: top left;
  background-repeat: repeat-x;
  background-size: 6px 16px;
}
.zigzagline {
  height: 16px;
  z-index: 1;
  transform: rotate(90deg);
}
.zigzaglineLeft{
  position: absolute;
  right: 23px;
  width: 26px;
  top: 9px;
  transform: rotate(270deg);
}
.zigzaglineRight{
  position: absolute;
  right: 0px;
  width: 28px;
  top: 9px;
}
.MuiTablePagination-toolbar {
  margin-top: 16px;
}

.filter-dropdown-field-wrapper .MuiSlider-colorPrimary {
  color: #0082b2 !important;
}

.filter-dropdown-field-wrapper .MuiSlider-colorPrimary .MuiSlider-thumb > span {
  top: -34px !important;
  left: calc(-50% - 4px) !important;
}
.filter-dropdown-field-wrapper .MuiSlider-thumb > span > span {
  background-color: #0082b2;
}

.apexcharts-xaxistooltip-text {
  min-width: 300px !important;
}
input#standard-basic {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
}
.MuiTablePagination-displayedRows {
 margin: 0 !important;
}

.apexcharts-legend.position-bottom {
  display: none;
}

.icdcode-table {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.hyperlink {
  color: #3aa290 !important;
  font-size: 13px !important;
  text-decoration: underline;
  cursor: pointer;
}

.hyperlink:hover {
  cursor: pointer;
}


.gt-report-box{
  min-height: 100vh !important;
  height: 100% !important;
}

.attr_chart_header {
  display: flex;
  justify-content: space-between;
}

.custom_attr_chart {
  svg {
    overflow: visible;
  }
}

.modifiers-section .save-filters-button {
  margin-left: 15px;
  outline: none !important;
  color: #0082b2;
  background-color: white !important;
  border: #0082b2 solid 1px;
}

.save-filter-section {
  max-height: 175px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  flex-wrap: nowrap;
}

.save-filters-button:hover {
  margin-left: 15px;
  outline: none !important;
  color: #fff;
  background-color: #0082b2 !important;
}

.save-filter-input {
  border-radius: 20px;
}

.patient-set-container {
  background-color: #fff;
  margin: 15px;
  padding: 20px;
  box-shadow: 0 0 2px 0 rgba(145,158,171,.2), 0 12px 24px -4px rgba(145,158,171,.12);
  border-radius: 14px!important;
}

.intervention-scroll-container,
.contributor-scroll-container {
  min-height: 250px;
  height: 425px;
  scroll-behavior: auto;
  overflow: scroll;

  .progress-bar {
    color: white;
  }
}
.intervention-scroll-container {
  .progress-bar {
    // color: white;
    font-size: 12px !important;
    padding: 5px 0px 0px 5px;
  }
}
.patient-set-create-cohort-container {
  .upload-inner {
    min-height: auto;
  }
}

.patient-set-trash {
  fill: #E16567 !important;
}

.popup-width{
  .MuiDialog-paper{
    min-width: 500px;
  }
}

.popup-text {
  cursor: pointer;
  color: #007bff;
}

.speedometer-warning {
  font-size: 24px !important;
  position: absolute !important;
  top: 65% !important;
  left: 70% !important;
  transform: translate(-50%, -50%) !important;
  fill: #ffcc00 !important;

  @include respond-to(media-xl) {
    top: 79% !important;
    left: 58% !important;
  }
}

.tablecell-baseline {
  vertical-align: baseline !important;
}

.map-overlay-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  z-index: 1000;
}
.skeleton-loader-1 {
  background-color: #fff;
  height: 100%;
  width: 100%;
  border-bottom: none;
}
.skeleton-loader-2 {
  // background-color: #fff;
  height: 100%;
  width: 100%;
  border-top: none;
}

.geospatial-marker{
  width: 100%;
  min-height: 50px;

  p{
    margin: 0 !important;
    padding: 0 !important;
  }
  .name{
    font-weight: 600;
    margin-bottom: 5px !important;
  }
  .address{
    margin-bottom: 5px !important;
  }
}

.position-relative{
  position: relative;
}

.patient-count-skeleton{
  position: absolute;
  top: 10%;
  right: 2%;
  z-index: 1000;
}

.geospatial-patient-count{
  background-color: #fff;
  position: absolute;
  top: 10%;
  right: 2%;
  z-index: 1000;
  border-radius: 5px;
  padding: 10px 20px !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #000;
}

