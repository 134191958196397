.patient-info-container {
  // Container for the entire patient info section
  width: 100%;
  background: #fff;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 5px;
  overflow: hidden;
  padding: 10px;



  .main-info-wrapper {
    // Wrapper for the main information section


    .info-btn-wrapper {
      // Wrapper for buttons related to patient info
      display: flex;
      justify-content: space-between;
      padding: 0px 10px;

      .icon-wrapper {
        display: flex;
        margin-left: auto;
        align-items: center;

        .full-screen-icon {
          margin-right: 10px;
          cursor: pointer;
        }

        .close-icon {
          padding: 0px;
        }
      }

      .prev-btn,
      .next-btn {
        color: black;
        font-weight: bold;
        font-size: 14px;
        text-transform: capitalize;

        &.Mui-disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        // .MuiButton-startIcon{
        //   margin-right: 4px !important;
        // }

        .MuiSvgIcon-root {
          fill: inherit !important;
          font-size: 20px;
        }

      }

    }

    .patient-info {
      display: flex;
      gap: 5%;
      padding: 10px;
      align-items: center;

      .patient-age {
        text-transform: capitalize;

        .gender-icon {
          .MuiSvgIcon-root {
            fill: inherit !important;
          }

        }
      }

      .patient-name {
        color: #0082b2;
        font-size: 1rem;
        font-weight: 600;
      }

      .patient-coodinators-tooltip {
        margin-left: auto;
        .coordinator-list{
          display: flex;
          gap: 10px;
          align-items: center;

        }
      }
    }

  }
}

.loading-wrapper {
  display: grid;
  place-items: center;
  height: 100vh;
}

.ul-popover{
  padding: 10px 50px;
  list-style-type: disc;
  font-size: 12px;
  list-style-position: inside;
  border: 1px solid #0082b2;
  // box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}

.screening-details-list{
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .first-screen-child{
    color: #0082b2;
    font-weight: 600;
  }
  li{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
}
