.patient-details-container {
  background-color: white;
  width: auto;
  .inside-container {
    padding-top: 16px;
    overflow: auto;
    max-height: 100vh;
    .no-data {
      display: flex;
      justify-content: center;
    }
  }
}

// .iframe-container {
//   width: 397px;
//   height: calc(100vh - 200px);
// }
