.download-csv-container {
  padding: 0 30px;
  margin-top: 20px;

  .csv-button-wrapper {
    display: flex;
    padding: 0px 0px 0px 0px;
    max-width: 75%;
    margin: 0 auto;
    justify-content: flex-end;
  }
}

.ccs_iframe_active {
  .csv-btn {
    background-color: #00ab55 !important;
    font-family: "Public Sans", sans-serif !important;
  }
}