.slider-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contributor-row{
    display: flex;
    justify-content: space-between;
    .contributor-left-row{
        height: 28px;
    }
    .contri-checkpoint{
        top: 32px !important;
    }
}