.reportContainer {
  padding: 0 30px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .footerHead {
    display: flex;
    align-items: center;
    color: #5a6370;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.2px;
    line-height: 16px;
    margin-bottom: 16px;

    .logo {
      margin-left: 12px;
    }
  }
}
