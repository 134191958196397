#radial-bar-chart {
  position: relative;
}

.custom-legend {
  position: absolute;
  top: 17px;
  font-size: 14px;
  line-height: 1.5;
  max-width: 200px;
}

.custom-legend-expanded-view {
  left: 20px;
  top: 13px;
  max-width: 'inherit';
}

.legend-item-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
}

.legend-item {
  cursor: pointer;
  margin-bottom: 1px;
  margin-right: 5px;
  font-size: 11px;
  font-weight: 400;
  position: relative;
  right: 0;
  white-space: nowrap;
  direction: ltr;
  text-align: right;
  width: 150px;
}

.legend-item-expanded-view {
  margin-bottom: 0px;
  font-size: 9.75px;
  width: 260px;
}

.legend-item::after {
  content: attr(data-tooltip);
  background-color: #CEF0FF;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  white-space: wrap;
  max-width: 250px;
  width: 120%;
  text-align: center;
  position: absolute;
  top: 100%;
  /* Show above the element */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  visibility: hidden;
}

.legend-item:hover::after {
  opacity: 1;
  visibility: visible;
}