@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

// CCS APP FONT
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;700&display=swap');

*{box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box;}
body {margin: 0; font-family: 'Poppins', sans-serif; padding: 50px 0 0 0;}
code { font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',  monospace;}
img{
  max-width: 100%;
  max-height: 100%;
  position: relative;
  right: 6px;
}
.container{
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 10px;
  margin-top: 60px;
}
.clearfix:before, .clearfix:after {content:''; display:block; height:0; overflow:hidden;}
.clearfix:after {clear:both;}

body {
  margin: 0px 0px 0px 0px;
  overflow: auto;
  padding: 0px !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}


::-webkit-scrollbar       {background-color:#fff;width:16px}
::-webkit-scrollbar-track {background-color:#fff}
::-webkit-scrollbar-thumb {background-color:#babac0;border-radius:16px;border:4px solid #fff}

.ccs__iframe__active{
  font-family: "Public Sans", sans-serif !important;
}