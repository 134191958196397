@mixin heading1 {
  font-size: 5.5rem;
  font-weight: bold;
  background: linear-gradient(to bottom right, #0082b2, #4da8c9);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

@mixin heading2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-weight: 700;
  color: #0000008c;
}

@mixin heading3 {
  font-size: 1rem;
  font-weight: 600;
  color: #0000006b;
}

@mixin outerContainer {
  width: 100%;
  background: linear-gradient(to bottom right, #0082b2, #4da8c9);
  position: relative;
}

@mixin paragraph {
  padding: 0;
  margin: 0;
  color: #0000009d;
}

@mixin innerCOntainer {
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  min-width: 30%;
}

.error-main-container {
  @include outerContainer;
  background: #fff !important;
  height: 100vh;

  .inner-container {
    @include innerCOntainer;
    padding: 30px 20px;

    p {
      @include paragraph;
    }

    .heading1 {
      @include heading1;
      font-size: 32px !important;
      font-weight: 600;
      color: #141414;
      margin-top: 10px !important;
    }

    .heading2 {
      @include heading2;
      font-size: 18px !important;
      margin-top: 10px !important;
      color: #0066CB !important;
    }

    .heading3 {
      @include heading3;
    }
  }
}

.no-data-main-container {
  @include outerContainer;
  height: calc(100vh - 70px);
  margin-top: -22px;

  .inner-container {
    @include innerCOntainer;
    padding: 20px 80px;

    p {
      @include paragraph;
    }

    .heading1 {
      @include heading1;
    }

    .heading2 {
      @include heading2;
    }

    .heading3 {
      @include heading3;
    }
  }
}