.patient-create-set-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  margin-left: 2px;
}

.input-field-size{
  width: 92% !important;
  margin-top: 0.9em !important;
}

.mui-textfield-form {
  .form-label {
    float: left;
    margin: 15px 0 -10px 20px;
    font-size: 15px;
  }
}

.MuiFormControl-root {
align-items: center;
}

.WithStyles\(ForwardRef\(InputBase\)\)-input-13 {
padding: 7px 26px 7px 12px !important; 
}
