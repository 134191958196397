
  .upload-outer {
    width: 33%;
    padding-top: 40px;
    margin: 0px auto 0px auto;
    // height: 90vh;
  }

  .upload-home {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .upload-home span {
    font-size: 13px;
    color: #0082b2;
  }

  .upload-home svg {
    vertical-align: middle !important;
    color: #0082b2;
    margin-right: 2px;
    font-size: 17px;
  }

  .upload-inner {
    background-color: #fff;
    width: 100%;
    min-height: 590px;
    padding: 10px 20px;
    height: auto;

    .upload-logo {
      display: flex;
      justify-content: center;
      padding: 15px;

      img {
        width: 14em;
      }
    }

    .upload-title {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      text-align: center;
      margin-top: 5px;
    }

    .bold{
      font-weight: bold;
    }

    .upload-subtitle {
      font-size: 13px;
      font-weight: 400;
      line-height: 1.5;
      color: #666;
      text-align: center;
      margin-top: 5px;
      margin-bottom: 20px;
    }

    .upload-submit {
      display: flex;
      justify-content: center;
      margin-top: 28px;

      .MuiButton-containedPrimary {
        background-color: #0082b2 !important;
        width: 100% !important;
      }
      .MuiButton-root.Mui-disabled {
        color: #fff !important;
        opacity: 0.6;
      }
    }

    .upload-limits {
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      margin-top: 5px;
      color: #bbb9b9;
      text-align: left;

      .MuiSvgIcon-root {
        font-size: 16px;
      }
    }

    .upload-preview {
      width: 100%;
      font-size: 14px;
      border-width: 2px;
      border-radius: 2px;
      border-color: #eeeeee;
      border-style: dashed;
      background-color: #fafafa;
      color: rgb(134, 117, 117);
      padding: 10px;
      margin-top: 15px;

      .upload-preview-flex {
        display: flex;
        justify-content: space-between;
      }

      .MuiSvgIcon-root {
        font-size: 19px;
      }

      .MuiLinearProgress-root {
        margin-top: 7px !important;
      }

      .MuiLinearProgress-colorPrimary {
        background-color: rgb(204, 212, 228);
      }

      .MuiLinearProgress-barColorPrimary {
        background-color: #0082b2;
      }
    }
  }
  .upload-inner.upload-inner-cohort {
    height: fit-content;
    margin-top: 0px;
  }

.upload-wrapper {
  .MuiAlert-icon {
    margin-right: 4px !important;
  }
  .MuiSvgIcon-root {
    fill: #fff !important;
  }
  .MuiAlert-root {
    border: 0px solid #fff !important;
  }
  .MuiAlert-root {
    font-weight: 600 !important;
    font-size: 16px !important;
  }
  .MuiAlert-filledSuccess {
    background-color: #13b322a8 !important;
  }
  .MuiAlert-filledError {
    background-color: #ff0000c9 !important;
  }
  
}

.success-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 410px;
  background-color: #fff;
  box-shadow: 24;
  padding: 4px;
  height: auto;
  max-height: 250px;
  overflow: auto;

  .close-icon {
    width: 100%;
    text-align: right;
    padding: 3px;
  }

  .add-link {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
  
    .fileUploaded {
      font-size: 20px;
      text-align: center;
    }
  
    .cohort-link {
      display: flex;
      gap: 15px;
  
      .tblBtn {
        width: 150px;
        color: #0082b2;
        border: #0082b2 solid 1px;
        font-size: 14px;
        line-height: 30px;
        margin: 0 5px;
        background: #fff;
        border-radius: 15px;
        cursor: pointer;
        outline: none !important;
        text-transform: capitalize;
  
        &:hover {
          outline: none !important;
          color: #fff;
          background-color: #0082b2;
      }
      }
    }
    .duplicateIdsTitle {
      font-size: 15px;
      display: flex;
      justify-content: center;
      width: 100%;
      font-weight: 600;
    }
    .duplicateIdsContainer {
      height: auto;
      min-height: 40px;
      background-color: #fff7e5;
      width: 100%; 
      padding: 5px;
    }
    .duplicateIds {
      font-size: 14px;
      text-align: center;
    }
  }
}
