.score-container,
.spinner-container {
  display: flex;
  flex-direction: row;
  width: 350px;
  min-height: 120px;
  padding: 10px;
  position: relative;
}

.score-container {
  justify-content: space-between;
}

.spinner-container {
  justify-content: center;
  color: rgb(0, 171, 85);
  align-items: center;
}

.score-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.score-label {
  color: #61666a;
  margin-top: 10px;
  font-weight: 500;
}

.score-avatar {
  width: 85px !important;
  height: 85px !important;
  font-weight: 600;
  color: #61666a !important;
  font-size: 15px !important;
  text-transform: capitalize;
}

.critical {
  background-color: #f4755f !important;

}

.high {
  background-color: #fcad60 !important;
}

.medium {
  background-color: #a6e7db !important;
}

.low {
  background-color: #88d479 !important;
}