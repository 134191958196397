.section__container {
    padding: 0 30px;

    .tableWrap {
        max-width: 75%;
        margin: 0 auto;
        position: relative;
        background-color: #ffffff;
        border-radius: 0 0 16px 16px;
        box-shadow: 0 6px 16px 2px rgba(90, 99, 112, 0.2);

        .tableScroll {
            overflow-x: auto;
            overflow-y: unset;

            .table {
                table-layout: auto;
                border-collapse: separate;
                border-spacing: 0;
                width: 100%;
                max-width: 100%;
                white-space: nowrap;

                .tRow td:first-child,
                .tRow th:first-child {
                    padding-left: 20px;
                }

                thead th {
                    color: #8c94a1;
                    font-weight: 500;
                    letter-spacing: -0.14px;
                    font-size: 12px;
                    line-height: 15px;
                    padding: 18px 4px 16px;
                    vertical-align: top;
                    border-bottom: 1px solid #edeef2;
                    text-align: start;
                }

                thead th.timeCol {
                    color: #3B424E;
                    text-align: right;
                    padding-right: 40px;
                }

                .timeCol {
                    min-width: 120px;
                    width: 120px;
                    text-align: right;
                    position: sticky;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    background: #ffffff;
                    border-left: 1px solid #edeef2;
                }

                .timeCol::before {
                    content: "";
                    height: 100%;
                    width: 100px;
                    position: absolute;
                    left: -101px;
                    top: 0;
                    z-index: -1;
                    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
                }

                .timeCol:after {
                    content: "";
                    height: 100%;
                    position: absolute;
                    margin-left: -2px;
                    left: 0;
                    top: 0;
                    z-index: -1;
                    box-shadow: 0 0px 3px 1px rgba(0, 0, 0, 0.0);
                }

                tr>th,
                tr>td {
                    min-width: 100px;
                }

                .entryHasNotes td {
                    color: #333333;
                    font-size: 14px;
                    letter-spacing: -0.24px;
                    line-height: 22px;
                    padding: 12px 4px 4px;
                    vertical-align: top;

                    &.medium {
                        color: #a6e7db;
                        text-transform: capitalize;
                    }

                    &.high {
                        color: #fcad60;
                        text-transform: capitalize;
                    }

                    &.low {
                        color: #88d479;
                        text-transform: capitalize;
                    }

                    &.critical {
                        color: #f4755f;
                        text-transform: capitalize;
                    }
                }

                .entryHasNotes td.timeCol {
                    text-align: right;
                    padding-right: 40px;
                }
            }
        }


    }
}

.tableHeading,
.tableData {
    white-space: nowrap;
}

.InsideTable {
    min-width: 100px;
}

.no-result {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40vh;
    font-size: 20px;
}

.color-blue {
    cursor: pointer;
    color: #47A3C7 !important;
}

.trend-up-icon {
    width: 26px;
}