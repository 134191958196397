.homepage {
    .patient-set-flex {
        display: flex;
        padding-bottom: 25px;
    }

    .patient-set-outer-left {
        width: 60%;
        padding: 0px 20px 0px 40px;
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }
    .patient-set-outer-left.cohort-creation {
        margin-top: 0;
    }

    .patient-set-inner-left, .patient-set-inner-right {
        background-color: #fff;
        width: 100%;
        padding: 20px;

        .patient-set-title {
            font-size: 20px;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            text-align: left;
        }

        .patient-set-subtitle {
            font-size: 14px;
            font-weight: 300;
            line-height: 1.5;
            color: #212529;
            text-align: left;
            margin-top: 5px;
        }

        .db-table-ar {
            overflow-x: hidden;
            height: 350px;
            min-height: 270px;
            max-height: 300px;
            overflow-y: auto;
            display: block;
            margin: 15px 5px;

            table thead th {
                position: unset;
            }
        }

        .headings-wrapper {
            justify-content: center;
        }

    }

    .left-to-right {
        display: flex !important;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .patient-set-outer-right {
        width: 40%;
        padding: 0px 40px 0px 25px;
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }

    .patient-set-inner-right, .patient-set-inner-left {
        .tblBtn {
            margin-left: 15px;
            color: #0082b2;
            border: #0082b2 solid 1px;
            font-size: 14px;
            line-height: 30px;
            margin: 0 10px;
            background: #fff;
            border-radius: 15px;
            cursor: pointer;
            outline: none !important;
            text-transform: capitalize;
          }
          
          .tblBtn {
            &:hover {
                outline: none !important;
                color: #fff;
                background-color: #0082b2;
            }
          }
    }

    .patient-set-inner-right {
        background-color: #fff;
        width: 100%;
        padding: 20px;
    }
}

.MuiCircularProgress-colorPrimary {
    color: #6baaed !important;
}
 
.patient-table-link {
    cursor: pointer;
    color: #6baaed;

    &:hover {
      text-decoration: underline;
    }
}

.ps-table-refresh .MuiSvgIcon-root {
    font-size: 18px;
    margin-left: 4px;
    vertical-align: sub;
}

.header{
    height: 70px;
   
    .navbar {
        z-index: 10;
        background-color: #0082b2;
        color: #fff;
        padding: 0;
        margin: 0;

        .header-title {
            color: #fff;
            font-size: 2rem;
            font-weight: 500;
            @media (max-width: 767px) {
                font-size: 1rem;
            }
        } 
        
        .navbar-toggler{
            width: unset;
            margin-right: 4px;
            height: unset;
            color: #f8f9fa;
            border-color: #f8f9fa !important;
        }
    }

    .navbar-nav {
        .nav-item {
            .nav-link{
                color: #f8f9fa;
                font-weight: 500;
                padding: 1.5rem 1rem;
                font-size: 20px;
                @media (max-width: 767px) {
                    padding: 0.5rem 1rem;
                }
            }
            &:hover, &:focus{
                background-color: #f8f9fa;
                color: #0082b2;
            }
            @media (max-width: 767px) {
                text-align: center;
            }
        }
        .nav-item--btn{
            padding: 1.5rem 1rem;
            
            .nav-btn{
                background-color: #C76B47;
                color: #f8f9fa;
                font-size: 20px;
                font-weight: bolder;
                padding: 0.5rem 1rem;
                text-decoration: none;
                border-radius: 0.25rem;
                
            }
            @media (max-width: 767px) {
                text-align: center;
            }
            
        }

        .dropdown {
            
            &:hover .dropdown-toggle{
                color: #0082b2;
            }

            &:hover{
                color: #0082b2;
                font-weight: 400;
                .dropdown-menu {
                    display: block;
                }
            }

            .dropdown-item{
                color: #212529;
                font-weight: 500;
                font-size: 18px;
                width: 100%;
                &:hover , &:focus{
                    color: #6baaed;
                    text-decoration: none;
                    background-color: #f8f9fa;
                    
                }
            }

            .dropdown-menu{
                @media (max-width: 767px) {
                    border: none;
                    text-align: center;
                }
            }

            .dropdown-toggle {
                font-size: 20px !important;
                &:hover, &:focus{
                    border: none !important;
                    padding: 1.5rem 1rem;
                    background-color: #f8f9fa !important;
                    color: #0082b2 !important; 
                    @media (max-width: 767px) {
                        padding: 0.5rem 1rem;
                    }
                    
                }
                @media (max-width: 767px) {
                    text-align: center;
                }
                &:after{
                    margin-left: 10px;
                }
            }

            

        }
    }
}

.footer{
    background-color: #0082b2;
    color: #f8f9fa;
    position: absolute;
    left:0;
    bottom:0;
    right:0;
    @media (max-width: 767px) {
        position: relative !important;
    }
    
    li {
        line-height: 2.5;
        a {
            color: #f8f9fa;
            text-decoration: none;
            font-size: 18px;
        }
    }

    .footer-separator{
        border: 0; 
        border-top: 1px solid #f8f9fa;
    }

    .follow-us{
        h6{
            display: flex;
            justify-content: center;
            align-items: center;
            svg{
                fill: #f8f9fa !important    ;
                font-size: 1.7rem;
                margin: 0 7px;
            }
        }

    }
    .follow-us{
        i{
            color: #f8f9fa;
            font-size: 24px;
            margin: 0 10px;
        }
    }
    
}

.home-reports {
    width: 100%;
    padding: 0px 40px 0px 40px
}

.aka_user_list {
    display: flex;
}

.aka-list-table {
    max-height: 450px !important;
}

.aka-list-button {
    width: 50%;
}

.aka-list-back-button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}