.diabMetr .SlideOuterBx{
    position: absolute !important;
    left: 30%;
    top: 0;
}
.intervention-table-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px !important;
}

.diagNosOuter .left-side .diabet_left_inactive.filter .diabMetr span.MuiSlider-root .MuiSlider-rail{
    margin-top: -8px !important;
}
.diagNosOuter .left-side .diabet_left_inactive.filter .diabMetr span.MuiSlider-root .MuiSlider-track{
    margin-top: -8px !important;
}
.diagNosOuter .left-side .diabet_left_inactive.filter .diabMetr span.MuiSlider-root .MuiSlider-thumb{
    margin-top: -8px !important;
}
.diagNosOuter .left-side .diabet_left_inactive.filter .diabMetr .top-widget button{
    top: -34px !important;
}

.diagNosOuter .left-side .diabet_right.filter{
    padding-top: 0 !important;
    width: 29% !important;
}
.diagNosOuter .left-side .diabet_right.filter-heading-1{
    width: 31% !important;
}
.diagNosOuter.diagNosOuter-resp .left-side .diabetOut.filter-2 .progressOut{
    top: 7px !important;
}
.diagNosOuter.diagNosOuter-resp .left-side .diabetOut.filter-2 .progressOut .progress .progress-bar{
    height: 100% ;
    border-radius: 80px;
}
.diagNosOuter.diagNosOuter-resp .left-side .diabetOut.filter-2 span.MuiSlider-root .MuiSlider-rail{
    margin-top: -5px !important;
}
.diagNosOuter.diagNosOuter-resp .left-side .diabetOut.filter-2 span.MuiSlider-root .MuiSlider-track{
    margin-top: -5px !important;
}
.diagNosOuter.diagNosOuter-resp .left-side .diabetOut.filter-2 span.MuiSlider-root .MuiSlider-thumb{
    margin-top: -5px !important;
}
.diagNosOuter.diagNosOuter-resp .left-side .diabetOut.filter-2 .diabet_right{
    width: 33% !important;
}
.slider-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}