.sidebar-icon, .sidebar-icon-disabled {
    width: 24px;
    height: auto;
    right: 0px !important;
}

.sidebar-icon-lg {
    width: 33px;
    height: auto;
    right: 0px !important;
}

.sidebar-icon-sm {
    width: 20px;
    height: auto;
    right: 0px !important;
}

.sidebar-icon-md {
    width: 27px;
    height: auto;
    right: 2px !important;
}

.sidebar-icon-report {
    width: 22px;
    height: auto;
    right: 0px !important;
}

.premiumIcon {
    width: 11px;
    height: auto;
    right: -13px !important;
    position: absolute;
}


.MuiSvgIcon-root.expand-collapse-icon {
    fill: #000 !important;
    border: 2px solid #fff;
    border-radius: 50%;
    padding: 2px;
    background-color: #fff;
}

.openDrawer {
    .MuiButtonBase-root.MuiIconButton-root {
        position: fixed;
        bottom: 0;
        left: 221px;
        width: fit-content;
        z-index: 1200;
        transition: 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }
}

.closeDrawer {
    .MuiButtonBase-root.MuiIconButton-root {
        position: fixed;
        bottom: 0;
        left: 36px;
        width: fit-content;
        z-index: 1200;
        transition: 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        @media (max-height: 750px) {
            left: 51px;
        }
    }
}

.lockIcon.MuiSvgIcon-root {
    fill: black !important;
    position: relative;
    height: 15px !important;
    width: 15px !important;
    top: -9px;
}

.betaIcon{
    width: 30px;
    height: 32px;
    top: -15px;
    right: 0px;
}