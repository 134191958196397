.section__container {
  padding: 0 30px;

  .tableWrap {
    max-width: 75%;
    margin: 0 auto;
    position: relative;
    background-color: #ffffff;
    border-radius: 0 0 16px 16px;
    box-shadow: 0 6px 16px 2px rgba(90, 99, 112, 0.2);

    .tableScroll {
      overflow-x: auto;
      overflow-y: unset;

      .outer__table {
        table-layout: auto;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
        max-width: 100%;
        white-space: nowrap;

        .tRow td:first-child,
        .tRow th:first-child {
          padding-left: 20px;
        }

        .sticky_first {
          position: sticky;
          left: 0;
          background: white;
          min-width: 5px;
          width: 50px;
          z-index: 2;
        }

        thead th {
          color: #8c94a1;
          font-weight: 500;
          letter-spacing: -0.14px;
          font-size: 12px;
          line-height: 15px;
          padding: 18px 4px 16px;
          vertical-align: top;
          border-bottom: 1px solid #edeef2;
          text-align: start;
        }

        thead th.timeCol {
          color: #3b424e;
          text-align: right;
          padding-right: 40px;
        }

        .timeCol {
          min-width: 120px;
          width: 120px;
          text-align: right;
          position: sticky;
          right: 0;
          bottom: 0;
          top: 0;
          background: #ffffff;
          border-left: 1px solid #edeef2;
        }

        .timeCol::before {
          content: "";
          height: 100%;
          width: 100px;
          position: absolute;
          left: -101px;
          top: 0;
          z-index: -1;
          background: linear-gradient(
            270deg,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 100%
          );
        }

        .timeCol:after {
          content: "";
          height: 100%;
          position: absolute;
          margin-left: -2px;
          left: 0;
          top: 0;
          z-index: -1;
          box-shadow: 0 0px 3px 1px rgba(0, 0, 0, 0);
        }

        tr > th,
        tr > td {
          min-width: 100px;
        }

        .entryHasNotes td {
          color: #333333;
          font-size: 14px;
          letter-spacing: -0.24px;
          line-height: 22px;
          padding: 12px 4px 4px;
          vertical-align: top;
          border-top: 1px solid #edeef2 !important;

          &.medium {
            color: #a6e7db;
            text-transform: capitalize;
          }

          &.high {
            color: #fcad60;
            text-transform: capitalize;
          }

          &.low {
            color: #88d479;
            text-transform: capitalize;
          }

          &.critical {
            color: #f4755f;
            text-transform: capitalize;
          }
        }

        .entryHasNotes td.timeCol {
          text-align: right;
          padding-right: 40px;
        }

       
      }
    }
  }
}

.tableHeading,
.tableData {
  white-space: nowrap;
}

.icon-align{
  display: flex !important;
  align-items: center !important;
  gap: 5px;
}

.InsideTable {
  min-width: 100px;
}

.no-result {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
  font-size: 20px;
}

.color-blue {
  cursor: pointer;
  color: #47a3c7 !important;
}

.trend-up-icon {
  width: 26px;
}

.inside__table__heading{
  margin: 5px 0px;
  font-weight: 500;
}

.last-heading-col,
.last-row-col{
  padding-left: 40px !important;
  padding-right: 10px !important;
}
.inside_table_heading_th {
  vertical-align: middle; /* Vertically center text and icon */
}

.inside_table_heading_th svg {
  margin-left: 8px; /* Space between text and icon */
  vertical-align: middle; /* Vertically align icon with text */
  font-size: 16px !important;
  cursor: pointer;
}

.client-report-filter{
  max-width: 350px !important;
  min-width: 250px !important;
}

.client-report-filter-container{
  display: flex;
  align-items: center;
  gap: 10px;
}


