.date-select-container {
    margin-right: 15px;

    .MuiInputBase-root {
        width: 160px !important;
    }
    
    .MuiSelect-select.MuiSelect-select {
        padding-right: 0px !important;
    }
    
    .date-select-value {
        padding: 5px;
        display: flex;
        align-items: flex-end;
    
        span {
            align-self: flex-end;
        }
    
        svg {
            margin-right: 10px;
            font-size: 1.4rem;
        }
    }
}

.MuiListItem-root.Mui-selected {
    background: #f4f6f8 !important;
    border-radius: 35px 0 0 35px !important;
    margin: 7px 0 0 0 !important;

    .css-10hburv-MuiTypography-root {
        font-weight: bold;
    }
}

.MuiListItem-button {
    margin: 7px 3px 0 0 !important;
    transition: none !important;

    &:hover {
        background-color: #eff6f8;
        border-radius: 50px;
        margin: 0 3px;
    }
}