.akapage {
  .aka-outer {
    width: 33%;
    padding-top: 120px;
    margin: 0px auto 0px auto;
  }

  .aka-inner {
    background-color: #fff;
    width: 100%;
    height: 60vh;
    padding: 40px;

    .aka-logo {
      display: flex;
      justify-content: center;
      padding: 15px;

      img {
        width: 14em;
      }
    }

    .aka-title {
      font-size: 19px;
      font-weight: 400;
      line-height: 1.5;
      color: #666;
      text-align: center;
      margin-top: 32px;
    }

    .aka-input {
      input {
        height: 40px;
        font-size: 16px;
        border-radius: 20px;
      }
    }

    .aka-submit {
      button {
        width: 100%;
        outline: none!important;
        color: #0082b2;
        background-color: #fff;
        border: 1px solid #0082b2;
        height: 40px;
      }

      button:hover {
        outline: none!important;
        color: #fff;
        background-color: #0082b2;
      }
    }

  }
}