.dca-checkBox {
  padding: 0px 9px !important;
  .MuiSvgIcon-root {
    fill: #0082b2 !important;
  }
}

.file-outer{
  min-height: 100vh;

  .list-outer{
    display: flex;
    align-items: center;
    padding-top: 0;
    margin: 0px auto 0px auto;
    height: calc(100vh - 58px);
    width: 85vw;
  }
}

.dca-accordion-summary{
  display: flex;
  flex-direction: row;
}

.dca-accordion-success{
  fill: green !important;
  margin-left: 10px;
}

.dca-accordion-error{
  fill: red !important;
  margin-left: 10px;
}

.dca-accordion-warning{
  fill: #eed202 !important;
  margin-left: 10px;
}

.mui-snackAlert{
  background-color: white;
}

.hover {
  path {
    stroke-width: 3;
    stroke: #2993CE;
  }
}

.MuiButtonBase-root.MuiListItemButton-divider {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 0;
}

.MuiButtonBase-root.MuiListItemButton-root.Mui-selected {
  background-color: #fff;
  color: #0080B2;
  border-right: 3px solid #0080B2;
}

.breadcrums {
  margin: 2rem 3rem 2rem 3rem;
}