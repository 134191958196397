.dashboard-style {
    .MuiButton-root {
        text-transform: none;
    }
}

.line-graph {
    max-height: 40px;
    max-width: 40px;
    right: 15px;
    bottom: 10px;
}

.cohort-tabs {

    .MuiTab-textColorPrimary {
        font-weight: 600;
        text-transform: capitalize;
        color: #000;
    }

    .MuiTab-textColorPrimary.Mui-selected {
        color: #47a3c7 !important;
    }
}

.date-picker {
    .MuiFormLabel-root.Mui-focused {
        color: rgba(0, 0, 0, 0.6) !important;
    }
}

.comparison-table {
    .MuiTablePagination-root {
        width: 50% !important;
    }

    .MuiTablePagination-spacer {
        flex: none !important;
    }

    .MuiTableRow-root.Mui-selected {
        background-color: #CCE0FF;
        border: #000 solid 2px;

        & .MuiTableCell-root {
            border-bottom: 2px solid #0C66E4;
            border-top: 2px solid #0C66E4;
        }

        td:first-child {
            border-left: 2px solid #0C66E4;
        }

        td:last-child {
            border-right: 2px solid #0C66E4;
        }
    }

    .MuiTableRow-root.MuiTableRow-hover.Mui-selected {
        border: #000 solid 2px;
        background-color: #CCE0FF;
    }
}

.apex-radial-chart {

    justify-content: space-evenly;
    align-items: flex-start;

    .apexcharts-legend.apx-legend-position-left {
        align-items: end;
    }

    .apexcharts-legend-series {
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .apexcharts-legend-marker {
        display: none;
    }
}

.risk-contributor-list {

    .MuiTableContainer-root {
        margin-top: 10px;
        border: 1px solid #C8C8C8;
    }

    .MuiTableRow-head {
        & .MuiTableCell-root {
            background-color: #F2F2F2;
        }
    }

}

.pie-chart {
    .apexcharts-pie {
        transform: rotateX('180deg');
    }
}


.openDrawer {
    .MuiButtonBase-root.MuiIconButton-root {
        position: fixed;
        bottom: 0;
        left: 221px;
        width: fit-content;
        z-index: 1200;
        transition: 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }
}

.closeDrawer {
    .MuiButtonBase-root.MuiIconButton-root {
        position: fixed;
        bottom: 0;
        left: 34px;
        width: fit-content;
        z-index: 1200;
        transition: 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

        @media (max-height: 750px) {
            left: 40px;
        }
    }
}

.x-box{
	width: 22vh;
	height: 22vh;
	border-radius: 50%;
	background-color:green;
	background:repeating-conic-gradient(
		from 0deg,
		#ffadadba 0deg calc(3.6deg * 10),
		#FFE6C9BA calc(3.6deg * 10) calc(3.6deg * 40),
		#9BF6FFBA calc(3.6deg * 40) calc(3.6deg * 60),
		#CAFFBFBA calc(3.6deg * 60) calc(3.6deg * 100)
	);
}
.x-box-cont{
	font-size: 30px;
	padding-left: 50px;
}

.stacked-chart {
    display: flex;
    flex-direction: column;
    height: 230px; /* Adjust as needed */
    width: 120px; /* Adjust as needed */
  }
  
  .bar {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0066CB;
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.5s;
    min-height: 21px;
    
  }
  .bar:hover {
    opacity: 0.5;
  }

  .cohort-tab-list .MuiTabScrollButton-root {
    color: #47a3c7 !important;

    svg {
        width: 1.7em;
        height: 1.7em;
      }
  }

  .canvasPieChart{
    height: 100%;
    width: 100%;
  }