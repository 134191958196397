@import "../../mixin.scss";

.emulationOuter {
    padding: 15px 50px 60px 50px; 
    .emulatHd{
        h2{
            font-size: 24px;
            line-height: 40px;
            font-weight: 500;
            padding-top: 10px;
            text-transform: capitalize;
            @include respond-to(media-sm) {
                font-size: 18px;
            }
            img{
                display: inline-block;
                padding-right: 10px;
            }
        }
    }
    .SimulateTableOuter {
        background: #fff;
        padding: 20px;

        .silulateTitlHd {
            font-size: 18px;
            font-weight: 400;
            color: #282828;
            padding-bottom: 10px;
            @include respond-to(media-sm) {
                font-size: 16px;
            }
        }
        .simutableOuter{
            @include respond-to(media-sm) {
                overflow: auto;
            }
            table{
                border: 0;
                @include respond-to(media-sm) {
                    width: 700px;
                }
                tr{
                    th{
                        background: #eff4f8;
                        color: #9d9ea2;
                        font-weight: 600;
                        font-size: 14px;
                        padding: 10px 20px;
                        line-height: 25px;
                        border: 0;

                    }
                    td{
                        font-size: 14px;
                        color: #333;
                        line-height: 25px;
                        padding: 0.65rem;
                        text-align: center;
                        border:0px;
                        font-weight: 500;

                        &:nth-child(2){
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                        button{
                            cursor: pointer;
                            &:focus{
                                outline:0 !important;
                            }
                        }
                        .editBtn {
                            background: #fff;
                            color: #0080b2;
                            border: #0080b2 solid 1px;
                            width: 63px;
                            height: 23px;
                            border-radius: 15px;
                            margin-right: 10px;
                            font-size: 12px;
                            line-height: 20px;
                            &:hover {
                                color: #fff;
                                border: #16aee6 solid 1px;
                                background: #16aee6;
                            }
                        }
                        .deleteBtn {
                            background: #0080b2;
                            border: #0080b2 solid 1px;
                            width: 63px;
                            height: 23px;
                            border-radius: 15px;
                            margin-right: 10px;
                            color: #fff;
                            font-size: 12px;
                            line-height: 20px;
                            &:hover {
                                color: #fff;
                                border: #ff0000 solid 1px;
                                background: #ff0000;
                            }
                        }
                    }
                }
            }
        }
    }
}