.comparisiontableloader{
    padding-top: 0;
    height: 100%;

    .inner-container{
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding: 25px 50px !important;

        .header-container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            span{
                display: inline-block;
            }
        }

        .loader-containerr{
            display: flex;
            flex-direction: column;
            gap: 20px;

            .skeleton-loader{
 
                display: flex;
                justify-content: space-between;
                span{
                    display: inline-block;
                }
            }
        }
    }
}