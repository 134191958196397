.patient-notes {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  width: 100%;
  height: auto;
  max-height: calc(100vh * 0.7);
  overflow-y: scroll;
  gap: 20px;

  &.loading-wrapper{
    display: grid;
    place-items: center;
    min-height: calc(100vh * 0.3);
  }

  .patient-notes-wrapper {
    flex: 7;
    display: flex;
    gap: 20px;
    flex-direction: column;

    .notes-dropdown-wrapper {
      display: flex;
      gap: 40px;
    }

    .notes-wrapper {
      margin-top: 15px;
      max-height: calc(100vh * 0.25);
      overflow-y: scroll;
      min-height: calc(100vh * 0.15);

      .note-row {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;

        .note-title-row {
          display: block;
          font-size: 0.875rem;
          font-weight: 600;

          * {
            margin-right: 10px;

            &.interv-btn,
            &.cont-btn {
              text-transform: capitalize !important;
            }
          }
        }

        p {
          line-height: 1.5;
          font-size: 0.875rem;
        }
      }
    }

    .code-dismiss-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 20px;

      .icd-search-container {
        .chip-container {
          display: flex;
          position: absolute;


        }
      }

      .icd-search-container,
      .dismiss-container {
        display: flex;
        gap: 2%;
        align-items: center;
        position: relative;

        .aa-Form {
          border-radius: 5px !important;
          border: 1px solid #ced4da !important;
          color: #D3D3D3;
          height: 40px;
          margin-left: 2px;

          .aa-Label svg {
            color: #D3D3D3 !important;
          }
        }

        &>div:first-child {
          width: 80%;

          >.reason-input {
            height: 35px;
            color: #D3D3D3;
          }
        }

        .action-btn {
          width: 15% !important;
          height: 40px;
        }
      }

    }

  }

  .notes-tab-wrapper {
    flex: 3;

    .dismiss-tabs {

      .Mui-selected {
        color: #0080B2 !important;
      }

      .MuiTabs-indicator {
        background-color: #0080B2 !important;
      }
    }

    .tab-label {
      text-transform: capitalize;
      font-weight: 600;
    }

    
    .dismiss-table {
      width: 100%;
      // position: relative;

      .sticky-header {
        position: sticky;
        top: 0;
        background-color: #fff;
      }

      tbody {
        border-collapse: collapse;
        width: 100%;
      }

      thead th {
        color: #0082b2;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        padding: 18px 4px 16px;
        vertical-align: top;
        text-align: start;
      }

      td {
        // width: 33%;
        text-align: left;
        border-bottom: 1px solid #edeef2;
        padding: 12px 4px 4px;

      }
    }


  }

}

.note-title-row {
  width: 100%;
}

.cont-btn,
.interv-btn, .dismissed-btn {
  text-transform: none !important;
  color: black !important;
  font-size: 0.875rem !important;
  height: 20px !important;
}

.cont-btn {
  background-color: #FFFB96 !important;
}

.interv-btn {
background-color: #D1FF6E !important;
}

.dismissed-btn{
background-color: #DCDCDC !important;
}

.data-not-available{
  text-align: center;
  padding: 10px;
  font-weight: 600;
}


#demo-simple-select, .menu-item{
  text-transform: capitalize;
}

@media screen and (max-width: 600px) {
  .notes-dropdown-wrapper {
    flex-direction: column;
    gap: 20px !important; /* Adjust the gap for smaller screens */
    margin-bottom: 20px;
  }
}