.patient-create-set-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  margin-left: 2px;
}

.input-field-size{
  width: 92% !important;
  margin-top: 0.9em !important;
}

.patientid-select{
align-items: baseline !important;
.MuiInputBase-formControl {
  text-align: left;
  width: calc(100% - 20px);
}
}

.patientid-select{
  align-items: baseline !important;
  .MuiInputBase-formControl {
    text-align: left;
    width: calc(100% - 20px);
  }
}

.mui-textfield-form {
  .form-label {
    float: left;
    margin: 15px 0 -10px 20px;
    font-size: 15px;
  }
}

.MuiFormControl-root {
align-items: center;
}

.WithStyles\(ForwardRef\(InputBase\)\)-input-13 {
padding: 7px 26px 7px 12px !important; 
}


.feedback-icons {
.feedback-icons-flex {
  display: flex;
  align-items: center;

  span .MuiSvgIcon-root {
    font-size: 1.4rem;
  }

  .done {
    color: #29cc36 !important;
  }

  .close {
    color: #b60c0c !important;
  }
}
}

.not-found-id {
  width: 100%;
  font-size: 18px;
  text-align: center;
}
.id-title {
  text-align: left;
  font-size: 16px;
}
.id-description-container {
  height: auto;
  min-height: 40px;
  background-color: #fff7e5;
  width: 100%; 
  padding: 5px;
}
.id-description {
  text-align: center;
  font-size: 14px;
  padding: 1px;
}


.select-editable {position:relative; background-color:white; border:solid grey 1px;  width:100%; height:18px;}
.select-editable select {position:absolute; top:0px; left:0px; font-size:14px; border:none; width:100%; margin:0;}
.select-editable input {position:absolute; top:0px; left:0px; width:100px; padding:1px; font-size:12px; border:none;}
.select-editable select:focus, .select-editable input:focus {outline:none;}
